import { createSlice } from '@reduxjs/toolkit';

const tocsSlice = createSlice({
    name: 'tocs',
    initialState: {
        tocs: null,
    },
    reducers: {
        populateTocs(state, action) {
            state.tocs = action.payload; // payload is a field generated by Redux-Toolkit
        },
    },
});

export const tocsActions = tocsSlice.actions;

export default tocsSlice;
