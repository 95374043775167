import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    updates: JSON.parse(localStorage.getItem('stagedUpdates')) || [],
};
const stagedUpdatesSlice = createSlice({
    name: 'stagedUpdates',
    initialState,
    reducers: {
        addUpdate(state, action) {
            const newUpdate = action.payload;
            state.changed = true;

            state.updates.push({
                originalInterventionInterventionKey: newUpdate.originalInterventionInterventionKey,
                originalInterventionMasterInterventionKey:
                    newUpdate.originalInterventionMasterInterventionKey,
                originalInterventionRootCode: newUpdate.originalInterventionRootCode,
                originalInterventionSymptom: newUpdate.originalInterventionSymptom,
                originalInterventionIsRelevant: newUpdate.originalInterventionIsRelevant,
                verifiedInterventionInterventionKey: newUpdate.verifiedInterventionInterventionKey,
                verifiedInterventionMasterInterventionKey:
                    newUpdate.verifiedInterventionMasterInterventionKey,
                verifiedInterventionRootCode: newUpdate.verifiedInterventionRootCode,
                verifiedInterventionSymptom: newUpdate.verifiedInterventionSymptom,
                verifiedInterventionIsRelevant: newUpdate.verifiedInterventionIsRelevant,
                originalRailwayPeriod: newUpdate.originalRailwayPeriod,
            });
        },
        removeUpdate(state, action) {
            const Intervention_Key = action.payload;
            state.changed = true;
            state.updates = state.updates.filter(
                (update) => update.originalInterventionInterventionKey !== Intervention_Key
            );
        },
        clearStagedUpdates(state) {
            state.updates = [];
            state.changed = false;
        },
    },
});

export const stagedUpdatesActions = stagedUpdatesSlice.actions;

export default stagedUpdatesSlice;
