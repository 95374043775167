import classes from './FetchDataButton.module.css';
import { BsFillCloudDownloadFill } from 'react-icons/bs';

const FetchDataButton = (props) => {
    return (
        <button className={classes.button} onClick={props.onClick} disabled={props.disabled}>
            <span className={classes.icon}>
                <BsFillCloudDownloadFill />
            </span>
            <span>Load Interventions</span>
        </button>
    );
};

export default FetchDataButton;
