import { useCallback, useMemo, useRef, useState } from 'react';
import classes from './JobsTable.module.css';
import { INTERVENTION_PARAMS as I_P, UPDATE_STATUS } from './util/constants';

import { useVirtualizer, notUndefined } from '@tanstack/react-virtual';

import { IconButton, ListItemIcon, Menu, MenuItem, Popper } from '@mui/material';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import CloseIcon from '@mui/icons-material/Close';
import SwapVertIcon from '@mui/icons-material/SwapVert';

import useSortAndFilter from '../hooks/useSortAndFilter';

import TitleRow from './TitleRow';
import SelectFilter from './SelectFilter';

import { customSortValue } from './util/util';

const JobsTable = (props) => {
    // create an object we can map through from original values to verified values
    const transformedJobsArr = props.data.map((job) => {
        return job.original_records
            .filter((record) => record.Update_Status === UPDATE_STATUS.ACCEPTED)
            .map((record, i) => {
                return {
                    [I_P.CREATED_TIME]: job.created_time,
                    [I_P.ID]: job.id,
                    [I_P.INTERVENTION_KEY]: record.Intervention_Key,
                    [I_P.JOB_STATUS]: job.job_status,
                    [I_P.MASTER_INTERVENTION_KEY]: `${record.Master_Intervention_Key} ${String.fromCharCode(8594)} ${job.updated_records[i]?.Master_Intervention_Key || record.Master_Intervention_Key}`,
                    [I_P.P_SYMPTOM]: `${record.P_Symptom} ${String.fromCharCode(8594)} ${job.updated_records[i]?.P_Symptom_Verified || record.P_Symptom}`,
                    [I_P.RAILWAY_PERIOD]: job.updated_records[i].Railway_Period,
                    [I_P.IS_RELEVANT]: `${record.Is_Relevant} ${String.fromCharCode(8594)} ${job.updated_records[i]?.Is_Relevant || record.Is_Relevant}`,
                    [I_P.P_ROOT_CODE]: `${record.P_Root_Code} ${String.fromCharCode(8594)} ${job.updated_records[i]?.P_Root_Code_Verified || record.P_Root_Code}`,
                };
            });
    });

    const transformedJobs = transformedJobsArr.flat();

    const { setSortOptions, setFilter, toggleDirection, filteredAndSorted, filterValues } =
        useSortAndFilter({ items: transformedJobs });

    // Active column header menu
    const [headerEl, setHeader] = useState(null);
    const menuOpen = Boolean(headerEl);

    const tableElement = useRef();
    const [popoverOpen, setPopoverOpen] = useState(false);

    const closeColumnMenu = () => {
        setHeader(null);
    };

    const sortFilterKey = useRef(null);

    const handleMenuFilterAction = (e) => {
        setPopoverOpen(true);
        setFilter({ key: sortFilterKey.current, query: e.target.value });
        closeColumnMenu();
    };

    // Handle actions when column menu is open
    const openColumnMenu = (event, key) => {
        setHeader(event.currentTarget.parentNode);
        sortFilterKey.current = key;
        setPopoverOpen(false);
    };
    const handleFilterClick = () => {
        closeColumnMenu();
        setPopoverOpen(true);
    };

    const handleMenuSortAction = (key) => {
        const sortValue = customSortValue(key);
        setSortOptions((previousValue) => ({ ...previousValue, key, sortValue }));
        toggleDirection(key);
    };

    // Virtualizer

    const tableRef = useRef(null);

    const virtualizer = useVirtualizer({
        count: filteredAndSorted.length,
        getScrollElement: useCallback(() => tableRef.current, []),
        estimateSize: useCallback(() => 55, []),
        overscan: useMemo(() => 30, []),
    });

    const jobs = virtualizer.getVirtualItems();

    // Set size of the first and last table tbody elements

    const [before, after] =
        jobs.length > 0
            ? [
                  notUndefined(jobs[0]).start - virtualizer.options.scrollMargin,
                  virtualizer.getTotalSize() - notUndefined(jobs[jobs.length - 1]).end,
              ]
            : [0, 0];

    return (
        <div style={{ height: '100%' }}>
            <div ref={tableRef} className={classes['table-scroll']}>
                <table className={classes.table}>
                    <thead ref={tableElement}>
                        <tr>
                            {props.headers.map((header) => {
                                const processedHeader = header.replace(/_/g, ' ');
                                return (
                                    <TitleRow
                                        key={header}
                                        header={processedHeader}
                                        onClick={(e) => openColumnMenu(e, header)}
                                        showFilter={!!filterValues?.[header]?.query}
                                        clearFilter={() => {
                                            setFilter({
                                                key: header,
                                                query: null,
                                            });
                                        }}
                                    />
                                );
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        {before > 0 && (
                            <tr>
                                <td colSpan={props.headers.length} style={{ height: before }}></td>
                            </tr>
                        )}
                        {jobs.map((virtualRow, i) => {
                            const job = filteredAndSorted[virtualRow.index];
                            return (
                                <tr
                                    key={`${job.id}_${job.Intervention_Key}_${i}`}
                                    style={{
                                        height: `${virtualRow.size}px`,
                                    }}>
                                    {props.headers.map((header) => (
                                        <td key={`${header}_${i}`}>{job[header]}</td>
                                    ))}
                                </tr>
                            );
                        })}
                        {after > 0 && (
                            <tr>
                                <td colSpan={props.headers.length} style={{ height: after }} />
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>

            <Menu
                id='column-menu'
                anchorEl={headerEl}
                open={menuOpen}
                onClose={closeColumnMenu}
                sx={{ top: '10px', left: '-10px' }}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                MenuListProps={{ 'aria-labelledby': 'basic-button' }}>
                <MenuItem onClick={handleFilterClick}>
                    <ListItemIcon>
                        <FilterAltIcon fontSize='small' />
                    </ListItemIcon>
                    Filter
                </MenuItem>
                <MenuItem onClick={() => handleMenuSortAction(sortFilterKey.current)}>
                    <ListItemIcon>
                        <SwapVertIcon fontSize='small' />
                    </ListItemIcon>
                    Order
                </MenuItem>
            </Menu>

            <Popper
                placement='bottom-start'
                anchorEl={tableElement.current}
                open={popoverOpen}
                modifiers={[{ name: 'flip', enabled: false }]}>
                <div className={classes['filter-wrapper']}>
                    <SelectFilter
                        initialFilterKey={sortFilterKey.current}
                        onChange={(e) => (sortFilterKey.current = e.target.value)}
                        headers={props.headers}
                        filterValue={filterValues?.[sortFilterKey.current]?.query}
                        handleMenuFilterAction={handleMenuFilterAction}
                        resetFilter={() => setFilter(null)}
                    />
                    <IconButton aria-label='close' onClick={() => setPopoverOpen(false)}>
                        <CloseIcon fontSize='inherit' />
                    </IconButton>
                </div>
            </Popper>
        </div>
    );
};

export default JobsTable;
