import { redirect } from 'react-router-dom';
import store from '../store/index';
import { uiActions } from '../store/ui-slice';

export function action() {
    // eslint-disable-next-line no-console
    console.log('logging out');
    localStorage.removeItem('frp_token');
    localStorage.removeItem('frp_refresh_token');
    localStorage.removeItem('frp_token_expiration');
    store.dispatch(uiActions.logout());
    return redirect('/auth');
}
