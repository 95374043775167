import Card from './UI/Card';
import classes from './Instructions.module.css';

const Instructions = () => {
    const instructionText = (
        <div className='instruction'>
            <div className={classes['instruction-text']}>
                {`The details to carry out the verification of the respective interventions for the
                Fleet Reliability Platform data are detailed below. Specifically, the following
                steps must be carried out:`}
                <ol className={classes['instruction-summary']}>
                    <li>{`Load Intervention Data`}</li>
                    <li>{`Update Master Intervention Key for each record, where required`}</li>
                    <li>{`Update Root Code for each record, where required`}</li>
                    <li>{`Update Symptom for each record, where required`}</li>
                    <li>{`Confirm whether the intervention is relevant or not`}</li>
                </ol>
            </div>
            <h3 className={classes['instruction-heading']}>{`Loading Data`}</h3>
            <p className={classes['instruction-text']}>
                {` Data must be loaded from the Fleet Reliability Platform database. Click the "Engine
                Interventions" tab. Click the required Train Operating Company and Railway Period,
                and then click "Load Interventions" to populate the Interventions required for
                verification. `}
            </p>
            <h3 className={classes['instruction-heading']}>{`Master Intervention Key`}</h3>
            <p className={classes['instruction-text']}>
                {`The Master Intervention Key is the Intervention Key of the primary intervention
                linked to any subsequent interventions. Should an intervention be related to a
                primary intervention, then the subsequent interventions should have their "Master
                Intervention Key"'s set to the "Intervention Key" of the primary intervention.`}
            </p>
            <h3 className='instruction-heading'>{`Root Codes`}</h3>
            <p className={classes['instruction-text']}>
                {`The Root Codes are a set of codes which define the Root Cause of an intervention
                occurring. Each intervention must have an associated Root Code. The Root Codes of
                unverified interventions are prepopulated through an automatic classification
                mechanism. If the prepopulated Root Code is not correct, the Root Code for the
                intervention must be updated from the drop-down list.`}
            </p>
            <h3 className={classes['instruction-heading']}>{`Symptoms`}</h3>
            <p className={classes['instruction-text']}>
                {`The Symptoms are a set of descriptions which define the reported Symptom of an
                intervention. Each intervention must have an associated Symptom. The Symptoms of
                unverified interventions are prepopulated through an automatic classification
                mechanism. If the prepopulated Symptom is not correct, the Symptom for the
                intervention must be updated from the drop-down list.`}
            </p>
            <h3 className={classes['instruction-heading']}>
                {`Relevance of Intervention to Fleet Reliability Platform`}
            </h3>
            <p className={classes['instruction-text']}>
                {`In the case that an Intervention which is not relevant to the Fleet Reliability
                Platform is contained within the dataset, this intervention should have it's "Is
                Relevant" field set to false. This will ensure that this intervention is no longer
                considered relevant.`}
            </p>
        </div>
    );
    return (
        <Card>
            <div>
                <h2>{`How to Use`}</h2>
                {instructionText}
            </div>
        </Card>
    );
};

export default Instructions;
