import { createSlice } from '@reduxjs/toolkit';

const globalErrorsSlice = createSlice({
    name: 'globalErrors',
    initialState: {
        errors: [],
        interventionsPageErrors: [],
        popupError: null,
        popupSuccess: null,
    },
    reducers: {
        addError(state, action) {
            const error = action.payload.error;
            const errorMsg = action.payload.errorMsg;
            const id = state.errors.length + 1;

            state.errors.unshift({
                error,
                errorMsg,
                id,
            });
        },
        clearError(state, action) {
            const id = action.payload;
            state.errors = state.errors.filter((errorItem) => errorItem.id !== id);
        },
        showPopupError(state, action) {
            const error = action.payload.error;
            const errorMsg = action.payload.errorMsg;
            const itemsList = action.payload.itemsList;

            state.popupError = { error, errorMsg, itemsList };
        },
        clearPopupError(state) {
            state.popupError = null;
        },
        showPopupSuccess(state, action) {
            const title = action.payload.title;
            const message = action.payload.message;
            const itemsList = action.payload.itemsList;

            state.popupSuccess = { title, message, itemsList };
        },
        clearPopupSuccess(state) {
            state.popupSuccess = null;
        },
    },
});

export const globalErrorsActions = globalErrorsSlice.actions;

export default globalErrorsSlice;
