import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchProfile } from '../store/profile-actions';

import ToastWidget from '../components/UI/ToastWidget';

import classes from './Profile.module.css';

const Profile = () => {
    const dispatch = useDispatch();

    // grab the profile loading status
    const profileIsLoading = useSelector((state) => state.ui.profileIsLoading);

    // grab the user profile fetch success status
    const profileFetchSuccess = useSelector((state) => state.ui.profileFetchSuccess);

    // grab the user profile data
    const profile = useSelector((state) => state.profile.profile);

    // wrap action creator in function, load then grab the user profile data
    const populateProfile = () => {
        dispatch(fetchProfile(`${process.env.REACT_APP_API_URL}/users/me`));
    };

    useEffect(() => {
        populateProfile();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <ToastWidget
                message={'Profile retrieval status'}
                isLoading={profileIsLoading}
                isSuccess={profileFetchSuccess}
            />
            <hr />
            <h1 className='username'>Profile</h1>
            <div className={classes['table-wrapper']}>
                <table className={classes.table}>
                    <thead>
                        <tr>
                            <th>Full Name</th>
                            <th>Email</th>
                            <th>Domain</th>
                            <th>Scope</th>
                            <th>Role</th>
                        </tr>
                    </thead>
                    <tbody>
                        {profile !== null &&
                            profile.roles.map((role) => {
                                return (
                                    <tr
                                        key={`${profile.id}_${role.split('.')[0]}_${role.split('.')[1]}_${role.split('.')[2]}`}>
                                        <td>{profile.full_name}</td>
                                        <td>{profile.email}</td>
                                        <td>{role.split('.')[0]}</td>
                                        <td>{role.split('.')[1]}</td>
                                        <td>{role.split('.')[2]}</td>
                                    </tr>
                                );
                            })}
                    </tbody>
                </table>
            </div>
        </>
    );
};

export default Profile;
