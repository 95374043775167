import classes from './ErrorToast.module.css';

import { IconContext } from 'react-icons';
import { BsFillXCircleFill } from 'react-icons/bs';

const ErrorToast = (props) => {
    // Takes error, message in props

    return (
        <div className={classes.widget}>
            <div className={classes.text}>
                <div className={classes.error}>{props.error}</div>
                <div className={classes.message}>{props.errorMsg}</div>
            </div>

            <IconContext.Provider value={{ color: 'red', size: '1.5rem' }}>
                <div className={classes['icon-container']} onClick={props.onClose}>
                    <BsFillXCircleFill />
                </div>
            </IconContext.Provider>
        </div>
    );
};

export default ErrorToast;
