import { useState } from 'react';
import classes from './TitleRow.module.css';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';

const TitleRow = ({ header, onClick, hideMenu, showFilter, clearFilter }) => {
    const [showButton, setShowButton] = useState(false);
    return (
        <th
            key={header}
            aria-haspopup='true'
            onMouseEnter={() => setShowButton(true)}
            onMouseLeave={() => setShowButton(false)}>
            {header}
            {!hideMenu && (
                <IconButton
                    onClick={onClick}
                    sx={{
                        position: 'absolute',
                        paddingTop: '2px',
                        top: 0,
                        right: -8,
                        display: !showButton ? 'none' : null,
                    }}>
                    <MoreVertIcon className={classes['column-buttons']} />
                </IconButton>
            )}
            <IconButton
                onClick={clearFilter}
                sx={{
                    position: 'absolute',
                    padding: '1px',
                    top: 0,
                    left: 0,
                    display: !showFilter ? 'none' : null,
                }}>
                <FilterAltOffIcon className={classes['column-buttons']} fontSize='small' />
            </IconButton>
        </th>
    );
};

export default TitleRow;
