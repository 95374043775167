import { BarChart } from '@mui/x-charts/BarChart';

const ChartCard = ({ data, keyValue, defaultValues }) => {
    const initialValues = defaultValues.reduce((acc, value) => {
        return { ...acc, [value]: 0 };
    }, {});

    const processedData = data.reduce((acc, obj) => {
        const objKey = obj[keyValue].split('-')[0];
        acc[objKey] = (acc[objKey] || 0) + 1;
        return acc;
    }, initialValues);

    const keys = Object.keys(processedData);
    const counts = Object.values(processedData);

    return (
        <div>
            <BarChart
                yAxis={[{ scaleType: 'band', data: keys }]}
                series={[{ data: counts, color: '#fdb462' }]}
                bottomAxis={{ disableLine: true }}
                leftAxis={{ disableLine: true }}
                width={250}
                height={40}
                margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
                layout='horizontal'
            />
        </div>
    );
};

export default ChartCard;
