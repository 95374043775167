import { interventionActions } from './interventions-slice';
import { prepareAuthToken } from '../components/util/auth';
import { uiActions } from './ui-slice';
import { globalErrorsActions } from './globalerrors-slice';
import { NUM_PERIODS } from '../components/util/constants';

export const fetchInterventions = (toc, sortKey, sortOption, urlRoute) => {
    return async (dispatch) => {
        const fetchData = async () => {
            const token = await prepareAuthToken();
            const response = await fetch(
                `${urlRoute}?` +
                    new URLSearchParams({
                        toc: toc,
                        num_periods: NUM_PERIODS,
                        sortKey: sortKey,
                        sortOption: sortOption,
                    }),
                {
                    headers: {
                        Authorization: 'Bearer ' + token,
                    },
                }
            );

            // Unauthorised
            if (response.status === 401) {
                throw new Error(response.statusText);
            }

            // No permissions
            if (response.status === 403) {
                throw new Error('You do not have enough permissions to perform this action.');
            }

            // Validation error
            if (response.status === 422) {
                throw new Error(response.message);
            }

            // Token expired error
            if (response.status === 498) {
                throw new Error(response.message);
            }

            // Internal server error
            if (response.status === 500) {
                throw new Error(response.message);
            }

            if (!response.ok) {
                throw new Error('Failed to fetch data');
            }

            const data = await response.json();
            return data;
        };

        try {
            dispatch(uiActions.setInterventionsLoadingStatus(true));
            const interventions = await fetchData(); // carry out http request prior to dispatching an action
            dispatch(uiActions.setInterventionsLoadingStatus(false));
            dispatch(uiActions.setInterventionsFetchSuccess(true));
            dispatch(interventionActions.populateInterventions(interventions.data)); // argument gets passed to reducer as 'payload'
            dispatch(uiActions.setSelectedToc(toc)); // update selected TOC
        } catch (error) {
            dispatch(uiActions.setInterventionsLoadingStatus(false));
            dispatch(uiActions.setInterventionsFetchSuccess(false));
            dispatch(
                globalErrorsActions.addError({
                    error: 'Failed to fetch data',
                    errorMsg: error.message,
                })
            );
        }
    };
};
