import EditInterventionForm from './EditInterventionForm';
import Modal from '../UI/Modal';

const EditInterventions = (props) => {
    return (
        <Modal onClose={props.onClose}>
            <EditInterventionForm
                onSubmit={props.onSubmit}
                IsInterventionVerified={props.IsInterventionVerified}
                masterInterventionKeys={props.masterInterventionKeys}
                rootcodes={props.rootcodes}
                symptoms={props.symptoms}
                isRelevant={props.isRelevant}
                verifiedInterventionIsRelevant={props.verifiedInterventionIsRelevant}
                originalInterventionIsRelevant={props.originalInterventionIsRelevant}
                isRelevantHandler={props.isRelevantHandler}
                verifiedInterventionRootCode={props.verifiedInterventionRootCode}
                originalInterventionRootCode={props.originalInterventionRootCode}
                rootcodeHandler={props.rootcodeHandler}
                verifiedInterventionSymptom={props.verifiedInterventionSymptom}
                originalInterventionSymptom={props.originalInterventionSymptom}
                symptomHandler={props.symptomHandler}
                verifiedInterventionMasterInterventionKey={
                    props.verifiedInterventionMasterInterventionKey
                }
                originalInterventionMasterInterventionKey={
                    props.originalInterventionMasterInterventionKey
                }
                masterInterventionKeyHandler={props.masterInterventionKeyHandler}
                originalInterventionInterventionKey={props.originalInterventionInterventionKey}
                masterInterventionKeyDropdownValue={props.masterInterventionKeyDropdownValue}
                rootCodeDropdownValue={props.rootCodeDropdownValue}
                symptomDropdownValue={props.symptomDropdownValue}
                isRelevantDropdownValue={props.isRelevantDropdownValue}
                masterInterventionKeyDropdownHandler={props.masterInterventionKeyDropdownHandler}
                rootCodeDropdownHandler={props.rootCodeDropdownHandler}
                symptomDropdownHandler={props.symptomDropdownHandler}
                isRelevantDropdownHandler={props.isRelevantDropdownHandler}
                currentInterventionKey={props.originalInterventionInterventionKey}
                legends={props.legends}
            />
        </Modal>
    );
};

export default EditInterventions;
