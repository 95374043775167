import { createSlice } from '@reduxjs/toolkit';

const interventionsSlice = createSlice({
    name: 'interventions',
    initialState: {
        interventions: null,
    },
    reducers: {
        populateInterventions(state, action) {
            state.interventions = action.payload; // payload is a field generated by Redux-Toolkit
        },
        updateIntervention() {
            // TODO: add list of updated interventions for user to be aware of previous updates
        },
    },
});

export const interventionActions = interventionsSlice.actions;

export default interventionsSlice;
