import classes from './MainNavigation.module.css';
import { NavLink, Form, useRouteLoaderData } from 'react-router-dom';
import TriggerLogo from '../../assets/TriggerDataLogo_White.png';
import PorterbrookLogo from '../../assets/Porterbrook_logo.png';
import StagedUpdatesButton from '../StagedUpdates/StagedUpdatesButton';

function MainNavigation() {
    const token = useRouteLoaderData('root');
    return (
        <header className={classes.header}>
            <div className={classes['logo-container']}>
                <img src={TriggerLogo} alt='Trigger Data Logo' />
                <img src={PorterbrookLogo} alt='Porterbrook Logo' />
            </div>

            {token && (
                <nav>
                    <ul className={classes.list}>
                        <li>
                            <NavLink
                                to='/'
                                end
                                className={({ isActive }) =>
                                    isActive ? classes.active : undefined
                                }>
                                Home
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                to='/engineInterventions'
                                className={({ isActive }) =>
                                    isActive ? classes.active : undefined
                                }>
                                Engine Interventions
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                to='/jobs'
                                className={({ isActive }) =>
                                    isActive ? classes.active : undefined
                                }>
                                Jobs
                            </NavLink>
                        </li>
                    </ul>
                    <ul className={classes.list}>
                        <li>
                            <StagedUpdatesButton />
                        </li>
                        <li>
                            <NavLink
                                to='/user'
                                className={({ isActive }) =>
                                    isActive ? classes.active : undefined
                                }>
                                Profile
                            </NavLink>
                        </li>
                        <li>
                            <Form action='/logout' method='post'>
                                <button className={classes.button}>Logout</button>
                            </Form>
                        </li>
                    </ul>
                </nav>
            )}
        </header>
    );
}

export default MainNavigation;
