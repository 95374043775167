import { createSlice } from '@reduxjs/toolkit';
import { FETCH_STATUS } from '../components/util/constants';

// TODO: Change the status with a simpler logic
const uiSlice = createSlice({
    name: 'ui',
    initialState: {
        editInterventionIsVisible: false,
        interventionsIsLoading: false,
        interventionsFetchSuccess: null,
        rootCodesIsLoading: false,
        rootCodesFetchSuccess: null,
        symptomsIsLoading: false,
        symptomsFetchSuccess: null,
        tocsIsLoading: false,
        tocsFetchSuccess: null,
        profileIsLoading: false,
        profileFetchSuccess: null,
        selectedTOC: '',
        selectedPeriod: '',
        selectedTOCDropdownValue: '',
        selectedPeriodDropdownValue: '',
        jobsIsLoading: false,
        jobsFetchSuccess: null,
        submittingUpdates: false,
        submitUpdatesSuccess: null,
        stagedUpdatesIsVisible: false,
        railwayPeriodsFetchStatus: FETCH_STATUS.PENDING,
    },
    reducers: {
        hideEditIntervention(state) {
            state.editInterventionIsVisible = false;
        },
        showEditIntervention(state) {
            state.editInterventionIsVisible = true;
        },
        setInterventionsLoadingStatus(state, action) {
            state.interventionsIsLoading = action.payload;
        },
        setInterventionsFetchSuccess(state, action) {
            state.interventionsFetchSuccess = action.payload;
        },
        setRootCodeLoadingStatus(state, action) {
            state.rootCodesIsLoading = action.payload;
        },
        setRootCodesFetchSuccess(state, action) {
            state.rootCodesFetchSuccess = action.payload;
        },
        setSymptomLoadingStatus(state, action) {
            state.symptomsIsLoading = action.payload;
        },
        setSymptomFetchSuccess(state, action) {
            state.symptomsFetchSuccess = action.payload;
        },
        setTocsLoadingStatus(state, action) {
            state.tocsIsLoading = action.payload;
        },
        setTocsFetchSuccess(state, action) {
            state.tocsFetchSuccess = action.payload;
        },
        setProfileLoadingStatus(state, action) {
            state.profileIsLoading = action.payload;
        },
        setProfileFetchSuccess(state, action) {
            state.profileFetchSuccess = action.payload;
        },
        setSelectedToc(state, action) {
            state.selectedTOC = action.payload;
        },
        setSelectedPeriod(state, action) {
            state.selectedPeriod = action.payload;
        },
        setSelectedTOCDropdownValue(state, action) {
            state.selectedTOCDropdownValue = action.payload;
        },
        setSelectedPeriodDropdownValue(state, action) {
            state.selectedPeriodDropdownValue = action.payload;
        },
        setJobLoadingStatus(state, action) {
            state.jobsIsLoading = action.payload;
        },
        setJobFetchSuccess(state, action) {
            state.jobsFetchSuccess = action.payload;
        },
        showStagedUpdates(state) {
            state.stagedUpdatesIsVisible = true;
        },
        hideStagedUpdates(state) {
            state.stagedUpdatesIsVisible = false;
        },
        setSubmittingUpdatesStatus(state, action) {
            state.submittingUpdates = action.payload;
        },
        setSubmitUpdatesSuccess(state, action) {
            state.submitUpdatesSuccess = action.payload;
        },
        setRailWayPeriodsStatus(state, action) {
            state.railwayPeriodsFetchStatus = action.payload;
        },
        logout() {
            // eslint-disable-next-line no-console
            console.log('resetting state');
            // setting state to undefined will reset state back to initial state
        },
    },
});

export const uiActions = uiSlice.actions;

export default uiSlice;
