import { FETCH_STATUS } from '../components/util/constants';
import { uiActions } from './ui-slice';
import { railwayPeriodsActions } from './railwayperiods-slice';

import { prepareAuthToken } from '../components/util/auth';

export const fetchRailwayPeriods = (numPeriods) => {
    return async (dispatch) => {
        const token = await prepareAuthToken();
        dispatch(uiActions.setRailWayPeriodsStatus(FETCH_STATUS.LOADING));
        fetch(
            `${process.env.REACT_APP_API_URL}/railwayPeriods?` +
                new URLSearchParams({ numPeriods }),
            {
                headers: {
                    Authorization: 'Bearer ' + token,
                    accept: 'application/json',
                },
            }
        )
            .then(async (response) => {
                const data = await response.json();
                if (!response.ok) {
                    throw new Error(data.message || 'Error loading');
                }
                dispatch(railwayPeriodsActions.populateRailwayPeriods(data));
                dispatch(uiActions.setRailWayPeriodsStatus(FETCH_STATUS.SUCCESS));
            })
            .catch(() => {
                dispatch(uiActions.setRailWayPeriodsStatus(FETCH_STATUS.ERROR));
            });
    };
};
