import {
    SORT_DIRECTION,
    ROLE_PERMISSIONS,
    FILTER_OPERATOR,
    INTERVENTION_PARAMS as I_P,
} from './constants';

// Get users roles and permissions
export const getUserRoles = (profile) => {
    if (!profile) {
        return {};
    }
    let rolesObject = {};

    profile.roles.forEach((role) => {
        const valuesArray = role.split('.');
        const domain = valuesArray[0];
        const scope = valuesArray[1];
        const permission = valuesArray[2];
        rolesObject = { ...rolesObject, [scope]: { permission, domain } };
    });
    return rolesObject;
};

// Check if user has WRITE permissions to all or selected TOC
export const hasWritePermission = (profile, toc) => {
    const roles = getUserRoles(profile);
    if (roles['*']) {
        return roles['*'].permission === ROLE_PERMISSIONS.WRITE ? true : false;
    }
    return roles[toc]?.permission === ROLE_PERMISSIONS.WRITE;
};

// filter array
export const filterItems = (itemsToFilter, filters) => {
    if (!filters || filters.length === 0) {
        return itemsToFilter;
    }
    // Filter by all chosen keys
    return Object.entries(filters).reduce((acc, [key, values]) => {
        if (!values) {
            return acc;
        }
        const { query = '', match = FILTER_OPERATOR.CONTAINS } = values;
        if (query.trim().length === 0) {
            return acc;
        }
        const partialValues = acc.filter((item) => {
            if (!item[key]) return null;
            if (match === FILTER_OPERATOR.EQUALS) {
                return escapeRegExp(query).match(
                    new RegExp(`^${item[key].replace(/ +(?= )/g, '')}$`)
                ); // Remove extra spaces
            }
            // Check if item key value includes all typed words
            const stringSet = item[key].toLowerCase();
            const queryWords = query.split(' ');

            return queryWords.every((word) => stringSet.includes(word.toLowerCase()));
        });
        return partialValues;
    }, itemsToFilter);
};

// sort array
export const sortItems = (itemsToSort, direction, key, sortValue) => {
    return itemsToSort.sort((item, previousItem) => {
        let itemKey = sortValue ? sortValue(item[key]) : item[key];
        let previousItemKey = sortValue ? sortValue(previousItem[key]) : previousItem[key];

        // If item value consists in just numbers convert its type to Number
        const convertedItemKey = !itemKey || isNaN(+itemKey) ? itemKey : +itemKey;
        const convertedPreviousItemKey =
            !previousItemKey || isNaN(+previousItemKey) ? previousItemKey : +previousItemKey;

        const typeA = typeof convertedItemKey;
        const typeB = typeof convertedPreviousItemKey;
        if (direction === SORT_DIRECTION.ASC) {
            // Sort items when they are the same type otherwise sort by type
            if (typeA === typeB) {
                return convertedItemKey > convertedPreviousItemKey
                    ? 1
                    : convertedItemKey < convertedPreviousItemKey
                      ? -1
                      : 0;
            } else {
                return typeA > typeB ? 1 : -1;
            }
        } else {
            if (typeA === typeB) {
                return convertedItemKey > convertedPreviousItemKey
                    ? -1
                    : convertedItemKey < convertedPreviousItemKey
                      ? 1
                      : 0;
            } else {
                return typeA > typeB ? -1 : 1;
            }
        }
    });
};

const escapeRegExp = (string) => {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
};

export const customSortValue = (key) => {
    if (key === I_P.RAILWAY_PERIOD) {
        return (value) => {
            let [year, period] = value.trim().split('P');
            period = period.padStart(2, '0');
            return [year, period].join();
        };
    }
};
