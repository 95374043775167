import { json } from 'react-router-dom';
import { action as logoutAction } from '../../pages/Logout';

export const getAuthToken = () => {
    const token = localStorage.getItem('frp_token');
    return token;
};

export const getAuthRefreshToken = () => {
    const refreshToken = localStorage.getItem('frp_refresh_token');
    return refreshToken;
};

export const getTokenDuration = () => {
    const storedExpirationDate = localStorage.getItem('frp_token_expiration');
    const expirationDate = new Date(storedExpirationDate);
    const now = new Date();
    const duration = expirationDate.getTime() - now.getTime();
    return duration;
};

export const validateAuthToken = () => {
    const token = getAuthToken();
    const tokenDuration = getTokenDuration();

    if (token && tokenDuration > 0) {
        return token;
    } else {
        return null;
    }
};

export const refreshAuthToken = async () => {
    const refreshToken = getAuthRefreshToken();

    if (!refreshToken) {
        return null;
    }

    const payload = {
        refresh_token: refreshToken,
    };

    const response = await fetch(`${process.env.REACT_APP_API_URL}/auth/token/refresh`, {
        method: 'POST',
        headers: {
            accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
    });

    if (response.status === 401) {
        // Unauthorised
        throw new Error(response.statusText);
    }

    if (response.status === 422) {
        // Validation error
        throw new Error(response.message);
    }

    if (!response.ok) {
        // TODO: log user out of front end on calling function?
        throw json({ message: 'Could not authenticate user.' }, { status: 500 });
    }

    const resData = await response.json();
    const token = resData.access_token;
    const newRefreshToken = resData.refresh_token; // TODO: Backend to provide new refresh token as per https://datatracker.ietf.org/doc/html/rfc6819#section-5.2.2.3

    localStorage.setItem('frp_token', token);
    localStorage.setItem('frp_refresh_token', newRefreshToken);

    const expiration = new Date();
    expiration.setHours(expiration.getHours() + 1); // must match expiration set in Auth.js

    localStorage.setItem('frp_token_expiration', expiration.toISOString());
    // eslint-disable-next-line no-console
    console.log('Completed refresh successfully');
};

export const prepareAuthToken = async () => {
    const validToken = validateAuthToken();
    if (!validToken) {
        try {
            await refreshAuthToken();
            const authToken = getAuthToken();
            return authToken; // return refreshed token
        } catch (error) {
            // logoutAction(); // only useful if used in context of React Router
            return null; // no token available to return
        }
    }
    return validToken; // token still valid; return it
};

export const loader = async () => {
    const token = validateAuthToken();
    if (!token) {
        return logoutAction();
    }
    return token;
};
