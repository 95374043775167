import { Box, CircularProgress } from '@mui/material';
import { useSelector } from 'react-redux';
import classes from './TableLoading.module.css';

export const InterventionsTableLoading = ({ noData }) => {
    // grab the interventions loading status
    const interventionsIsLoading = useSelector((state) => state.ui.interventionsIsLoading);

    // grab interventions fetch success status
    const interventionsFetchSuccess = useSelector((state) => state.ui.interventionsFetchSuccess);

    return (
        <TableLoading
            isLoading={interventionsIsLoading}
            isSuccess={interventionsFetchSuccess}
            isError={interventionsFetchSuccess === false && !interventionsIsLoading}
            initialText='Select TOC/Period to see the respective records'
            noDataText='No Data Available for Selected TOC/Period Combination'
            noData={noData}
        />
    );
};

export const JobsTableLoading = ({ noData }) => {
    // grab the Jobs loading status
    const jobStatusIsLoading = useSelector((state) => state.ui.jobsIsLoading);

    // grab the Jobs fetch success status
    const jobStatusFetchSuccess = useSelector((state) => state.ui.jobsFetchSuccess);

    return (
        <TableLoading
            isLoading={jobStatusIsLoading}
            isSuccess={jobStatusFetchSuccess}
            isError={jobStatusFetchSuccess === false && !jobStatusIsLoading}
            noDataText='No Data Available for Jobs'
            noData={noData}
        />
    );
};

const TableLoading = ({ isLoading, isError, isSuccess, noData, initialText, noDataText }) => {
    if (isLoading) {
        return (
            <Box
                className={classes['loading-wrapper']}
                sx={{ display: 'flex', color: 'var(--color-head-columns)' }}>
                <CircularProgress color='inherit' />
            </Box>
        );
    }
    if (isSuccess && noData) {
        return (
            <Box className={classes['loading-wrapper']} sx={{ display: 'flex' }}>
                {noDataText}
            </Box>
        );
    } else if (!isError && noData && initialText) {
        return (
            <Box className={classes['loading-wrapper']} sx={{ display: 'flex' }}>
                <p>{initialText}</p>
            </Box>
        );
    }

    return null;
};
