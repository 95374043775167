import { uiActions } from './ui-slice';
import { stagedUpdatesActions } from './stagedupdates-slice';
import { globalErrorsActions } from './globalerrors-slice';
import { prepareAuthToken } from '../components/util/auth';
import { fetchInterventions } from './interventions-actions';

const JOB_STATUS = {
    CONFLICT: 'CONFLICT',
};

export const submitBatchUpdates = (stagedUpdates, toc) => {
    return async (dispatch) => {
        const token = await prepareAuthToken();
        const payload = stagedUpdates.map((update) => {
            const originalInterventionIsRelevantMapped =
                update.originalInterventionIsRelevant.toUpperCase() === 'YES' ? 'true' : 'false';
            const verifiedInterventionIsRelevantMapped =
                update.verifiedInterventionIsRelevant.toUpperCase() === 'YES' ? 'true' : 'false';
            return {
                original_records: {
                    P_Root_Code: update.originalInterventionRootCode,
                    P_Symptom: update.originalInterventionSymptom,
                    Master_Intervention_Key: update.originalInterventionMasterInterventionKey,
                    Is_Relevant: originalInterventionIsRelevantMapped,
                    Intervention_Key: update.originalInterventionInterventionKey,
                    Railway_Period: update.originalRailwayPeriod,
                },
                updated_records: {
                    P_Root_Code: update.verifiedInterventionRootCode,
                    P_Symptom: update.verifiedInterventionSymptom,
                    Master_Intervention_Key: update.verifiedInterventionMasterInterventionKey,
                    Is_Relevant: verifiedInterventionIsRelevantMapped,
                    Intervention_Key: update.verifiedInterventionInterventionKey,
                    Railway_Period: update.originalRailwayPeriod,
                },
            };
        });

        //TODO: Create an external global apiRequest function
        const sendRequest = async () => {
            return fetch(`${process.env.REACT_APP_API_URL}/records`, {
                method: 'POST',
                body: JSON.stringify(payload),
                headers: {
                    Authorization: 'Bearer ' + token,
                    accept: 'application/json',
                    'Content-Type': 'application/json',
                },
            }).then(async (response) => {
                const data = await response.json();
                if (!response.ok) {
                    return Promise.reject({ ...data, status: response.status });
                }
                return data;
            });
        };

        dispatch(uiActions.setSubmittingUpdatesStatus(true));
        sendRequest().then(
            (response) => {
                // handle conflicted records
                if (response.message?.match(/conflict/g) && response.jobs?.original_records) {
                    // Get a list of Intervention Keys from conflicted records
                    const conflictsList = response.jobs.original_records
                        .filter((record) => record.Update_Status === JOB_STATUS.CONFLICT)
                        .map((record) => record.Intervention_Key);
                    dispatch(
                        globalErrorsActions.showPopupError({
                            error: 'Conflicted records',
                            errorMsg:
                                "The records with the follow Intervention keys coudn't be submitted due to a conflict with another request.",
                            itemsList: conflictsList,
                        })
                    );
                } else {
                    dispatch(
                        globalErrorsActions.showPopupSuccess({
                            title: 'Records submitted',
                            message: 'Records successfully submitted.',
                        })
                    );
                }
                dispatch(stagedUpdatesActions.clearStagedUpdates());
                dispatch(uiActions.setSubmittingUpdatesStatus(false));
                dispatch(uiActions.setSubmitUpdatesSuccess(true));
                // re pull interventions table
                dispatch(
                    fetchInterventions(
                        toc,
                        'Date',
                        'DESC',
                        `${process.env.REACT_APP_API_URL}/records`
                    )
                );
            },
            (error = {}) => {
                let errorMessage = `${error.message || 'Failed to submit updates. Please try again or contact support.'}`;
                let errorStatus = `${error.status ? `(${error.status})` : ''}`;
                // search for conflict mention in error message
                dispatch(uiActions.setSubmittingUpdatesStatus(false));
                dispatch(uiActions.setSubmitUpdatesSuccess(false));
                if (error.message?.match(/conflict/g) && error.jobs?.original_records) {
                    // Get a list of Intervention Keys from conflicted records
                    const conflictsList = error.jobs.original_records
                        .filter((record) => record.Update_Status === JOB_STATUS.CONFLICT)
                        .map((record) => record.Intervention_Key);
                    // re pull interventions table
                    dispatch(
                        fetchInterventions(
                            toc,
                            'Date',
                            'DESC',
                            `${process.env.REACT_APP_API_URL}/records`
                        )
                    );
                    dispatch(
                        globalErrorsActions.showPopupError({
                            error: 'Conflicted records',
                            errorMsg:
                                "The records with the follow Intervention keys coudn't be submitted due to a conflict with another request.",
                            itemsList: conflictsList,
                        })
                    );
                    dispatch(stagedUpdatesActions.clearStagedUpdates());
                    return;
                }
                dispatch(
                    globalErrorsActions.addError({
                        error: 'Submission Failed',
                        errorMsg: `${errorMessage} ${errorStatus}`, // Include error status to the error message
                    })
                );
            }
        );
    };
};
