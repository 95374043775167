import { useSelector, useDispatch } from 'react-redux';
import classes from './StagedUpdates.module.css';

import StagedUpdatesTable from './StagedUpdatesTable';
import Modal from '../UI/Modal';
import Button from '../UI/Button';

import { submitBatchUpdates } from '../../store/stagedupdates-actions';

import { uiActions } from '../../store/ui-slice';
import { stagedUpdatesActions } from '../../store/stagedupdates-slice';

const StagedUpdates = (props) => {
    const dispatch = useDispatch();

    const stagedUpdates = useSelector((state) => state.stagedUpdates.updates);
    const submittingUpdates = useSelector((state) => state.submittingUpdates);
    const selectedToc = useSelector((state) => state.ui.selectedTOC);
    const selectedPeriod = useSelector((state) => state.ui.selectedPeriod);

    const submitHandler = async () => {
        dispatch(submitBatchUpdates(stagedUpdates, selectedToc, selectedPeriod));
        dispatch(uiActions.hideStagedUpdates());
    };

    const removeUpdateHandler = (interventionKey) => {
        dispatch(stagedUpdatesActions.removeUpdate(interventionKey));
    };

    return (
        <Modal onClose={props.onClose}>
            <div className={classes['staged-updates-wrapper']}>
                <h2 className={classes.title}>Staged Updates</h2>
                {stagedUpdates && (
                    <StagedUpdatesTable
                        headers={[
                            'Intervention Key',
                            'Master Intervention Key',
                            'Root Code',
                            'Symptom',
                            'Relevant?',
                            'Delete',
                        ]}
                        updates={stagedUpdates}
                        onRemove={removeUpdateHandler}
                    />
                )}
                <div className={classes['form-actions']}>
                    <Button
                        onClick={submitHandler}
                        disabled={submittingUpdates || stagedUpdates.length === 0}>
                        Submit Updates
                    </Button>
                </div>
            </div>
        </Modal>
    );
};

export default StagedUpdates;
