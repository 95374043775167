// Staged records
export const STAGED_STATUS = {
    IS_CONFLICTED: 'is_conflicted',
};

// Records update
export const UPDATE_STATUS = {
    CONFLICT: 'CONFLICT',
    ACCEPTED: 'ACCEPTED',
};

// User roles
export const ROLE_PERMISSIONS = {
    READ: 'READ',
    WRITE: 'WRITE',
};

// Sort direction
export const SORT_DIRECTION = {
    ASC: 'ASC',
    DESC: 'DESC',
};

// Filter match type
export const FILTER_OPERATOR = {
    EQUALS: 'EQUALS',
    CONTAINS: 'CONTAINS',
};

// Fetch status
export const FETCH_STATUS = {
    PENDING: 'PENDING',
    LOADING: 'LOADING',
    SUCCESS: 'SUCCESS',
    ERROR: 'ERROR',
};

// Interventions parametres
export const INTERVENTION_PARAMS = {
    CREATED_TIME: 'Created_Time',
    INTERVENTION_KEY: 'Intervention_Key',
    IS_RELEVANT: 'Is_Relevant?',
    JOB_STATUS: 'Job_Status',
    MASTER_INTERVENTION_KEY: 'Master_Intervention_Key',
    P_ROOT_CODE: 'P_Root_Code',
    P_SYMPTOM: 'P_Symptom',
    RAILWAY_PERIOD: 'Railway_Period',
    ID: 'id',
};

// Number of periods to be shown
export const NUM_PERIODS = 13;
