import { useDispatch, useSelector } from 'react-redux';

import { uiActions } from '../../store/ui-slice';
import classes from './StagedUpdatesButton.module.css';

const StagedUpdatesButton = () => {
    const dispatch = useDispatch();
    const stagedUpdates = useSelector((state) => state.stagedUpdates.updates);
    const numStagedUpdates = stagedUpdates.length;

    const showStagedUpdates = () => {
        dispatch(uiActions.showStagedUpdates());
    };

    return (
        <button className={classes.button} onClick={showStagedUpdates}>
            <span>Updates</span>
            <span className={classes.badge}>{numStagedUpdates}</span>
        </button>
    );
};

export default StagedUpdatesButton;
