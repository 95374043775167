import { useNavigate } from 'react-router-dom';
import classes from './Error.module.css';

const ErrorPage = () => {
    const navigate = useNavigate();

    return (
        <>
            <main>
                <h1>Uh-oh! There seems to be a problem!</h1>
                <p>
                    An unforeseen error has occurred. Please refresh the page and try again, and if
                    the problem persists, contact support.
                </p>
                <button className={classes.back} onClick={() => navigate(-1)}>
                    Go Back
                </button>
            </main>
        </>
    );
};

export default ErrorPage;
