import classes from './StagedUpdatesTable.module.css';

import { TiDelete } from 'react-icons/ti';
import { Tooltip } from '@mui/material';
import { IconButton } from '@mui/material';

const StagedUpdatesTable = (props) => {
    return (
        <div className={classes['table-wrapper']}>
            {props.updates.length === 0 ? (
                <div className={classes.empty}> No staged updates to show </div>
            ) : (
                <table className={classes.table}>
                    <thead>
                        <tr>
                            {props.headers.map((header) => (
                                <th key={header}>{header}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {props.updates.map((update) => {
                            return (
                                <tr key={update.originalInterventionInterventionKey}>
                                    <td>{update.originalInterventionInterventionKey}</td>
                                    <td>{`${update.originalInterventionMasterInterventionKey} ${String.fromCharCode(8594)} ${update.verifiedInterventionMasterInterventionKey}`}</td>
                                    <td>{`${update.originalInterventionRootCode} ${String.fromCharCode(8594)} ${update.verifiedInterventionRootCode}`}</td>
                                    <td>{`${update.originalInterventionSymptom} ${String.fromCharCode(8594)} ${update.verifiedInterventionSymptom}`}</td>
                                    <td>{`${update.originalInterventionIsRelevant} ${String.fromCharCode(8594)} ${update.verifiedInterventionIsRelevant}`}</td>
                                    <td>
                                        {
                                            <div className={classes['icon-container']}>
                                                <Tooltip title='Remove'>
                                                    <IconButton
                                                        sx={{
                                                            color: 'red',
                                                            padding: 0,
                                                            fontSize: '2rem',
                                                        }}
                                                        onClick={() =>
                                                            props.onRemove(
                                                                update.originalInterventionInterventionKey
                                                            )
                                                        }>
                                                        <TiDelete className={classes.icon} />
                                                    </IconButton>
                                                </Tooltip>
                                            </div>
                                        }
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            )}
        </div>
    );
};

export default StagedUpdatesTable;
