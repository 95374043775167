import { Icon } from '@mui/material';
import classes from './SuccessPopup.module.css';
import Button from './UI/Button';
import Modal from './UI/Modal';
import { FaCircleCheck } from 'react-icons/fa6';

const SuccessPopup = ({ message, itemsList, onClose, ...props }) => {
    return (
        <Modal {...props}>
            <div className={classes['success-wrapper']}>
                <p>
                    <Icon
                        color='success'
                        sx={{
                            fontSize: '3rem',
                            marginRight: '10px',
                        }}>
                        <FaCircleCheck className={classes.icon} />
                    </Icon>
                    {message}
                </p>
                <div style={{ padding: '10px' }}>
                    {itemsList && (
                        <ul>
                            {itemsList.map((item, i) => (
                                <li key={i}>{item}</li>
                            ))}
                        </ul>
                    )}
                </div>
                <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                    <Button onClick={onClose}>Close</Button>
                </div>
            </div>
        </Modal>
    );
};

export default SuccessPopup;
