import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Button from './UI/Button';
import { TextField } from '@mui/material';

const SelectFilter = ({
    initialFilterKey,
    filterValue,
    onChange,
    headers,
    handleMenuFilterAction,
    resetFilter,
}) => {
    return (
        <FormControl
            sx={{ m: 1, minWidth: 120, '& button': { m: 2 } }}
            variant='standard'
            id='draggable-dialog-title'>
            <InputLabel id='filter-key-label'>Column</InputLabel>
            <Select
                labelId='filter-key-label'
                id='filter-key'
                size='small'
                defaultValue={initialFilterKey}
                label='Column'
                variant='standard'
                onChange={onChange}>
                {headers.map((header) => (
                    <MenuItem key={header} value={header}>
                        {header}
                    </MenuItem>
                ))}
            </Select>

            <TextField
                label='Value'
                value={filterValue || ''}
                size='small'
                onChange={handleMenuFilterAction}
                variant='standard'
                id='filter-value'
            />

            <Button onClick={resetFilter}>Reset filters</Button>
        </FormControl>
    );
};

export default SelectFilter;
