import { createSlice } from '@reduxjs/toolkit';

const symptomsSlice = createSlice({
    name: 'symptoms',
    initialState: {
        symptoms: null,
    },
    reducers: {
        populateSymptoms(state, action) {
            state.symptoms = action.payload; // payload is a field generated by Redux-Toolkit
        },
    },
});

export const symptomActions = symptomsSlice.actions;

export default symptomsSlice;
