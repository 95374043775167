import { useEffect, useState } from 'react';
import { Outlet, useLoaderData, useSubmit } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import ErrorToast from '../UI/ErrorToast';

import { getTokenDuration, prepareAuthToken, validateAuthToken } from '../util/auth';

import MainNavigation from './MainNavigation';
import StagedUpdates from '../StagedUpdates/StagedUpdates';

import { uiActions } from '../../store/ui-slice';
import { globalErrorsActions } from '../../store/globalerrors-slice';

import classes from './Root.module.css';
import store from '../../store';
import { fetchProfile } from '../../store/profile-actions';
import ErrorPopup from '../ErrorPopup';
import SuccessPopup from '../SuccessPopup';

const RootLayout = () => {
    const token = useLoaderData();
    const dispatch = useDispatch();
    const submit = useSubmit();

    const [timeoutCompleteToggle, setTimeoutCompleteToggle] = useState(true); // TODO: This causes an extra re-render. Need to optimise this.

    const stagedUpdatesIsVisible = useSelector((state) => state.ui.stagedUpdatesIsVisible);

    const errors = useSelector((state) => state.globalErrors.errors);
    const popupError = useSelector((state) => state.globalErrors.popupError);
    const popupSuccess = useSelector((state) => state.globalErrors.popupSuccess);

    const clearError = (id) => {
        dispatch(globalErrorsActions.clearError(id));
    };

    const clearPopupError = () => {
        dispatch(globalErrorsActions.clearPopupError());
    };

    const clearPopupSuccess = () => {
        dispatch(globalErrorsActions.clearPopupSuccess());
    };

    const onClose = () => {
        dispatch(uiActions.hideStagedUpdates());
    };
    // get user profile
    useEffect(() => {
        const validToken = validateAuthToken();
        if (validToken) {
            // wrap action creator in function, load then grab the user profile data
            store.dispatch(fetchProfile(`${process.env.REACT_APP_API_URL}/users/me`));
        }
    }, []);

    useEffect(() => {
        const tokenDuration = getTokenDuration();
        // if not expired, logout once duration completes
        const timer = setTimeout(async () => {
            const token = await prepareAuthToken();
            if (!token) {
                localStorage.removeItem('frp_token');
                localStorage.removeItem('frp_refresh_token');
                localStorage.removeItem('frp_token_expiration');
                dispatch(uiActions.logout());
                submit(null, { action: '/logout', method: 'post' });
            } else {
                setTimeoutCompleteToggle(!timeoutCompleteToggle);
            }
        }, tokenDuration);

        return () => {
            clearTimeout(timer);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [timeoutCompleteToggle]);

    return (
        <>
            <MainNavigation />
            <main>
                <Outlet />
                {token && stagedUpdatesIsVisible && <StagedUpdates onClose={onClose} />}
                {errors && (
                    <div className={classes.errors}>
                        {errors.map((error) => (
                            <ErrorToast
                                key={error.id}
                                error={error.error}
                                errorMsg={error.errorMsg}
                                onClose={() => clearError(error.id)}
                            />
                        ))}
                    </div>
                )}
                {popupError && (
                    <ErrorPopup
                        errorMsg={popupError.errorMsg}
                        error={popupError.error}
                        itemsList={popupError.itemsList}
                        onClose={() => clearPopupError()}
                    />
                )}
                {popupSuccess && (
                    <SuccessPopup
                        message={popupSuccess.message}
                        onClose={() => clearPopupSuccess()}
                    />
                )}
            </main>
        </>
    );
};

export default RootLayout;
