import { useState, useEffect, useRef, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { INTERVENTION_PARAMS, NUM_PERIODS } from '../components/util/constants';

import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

import { uiActions } from './../store/ui-slice';
import { stagedUpdatesActions } from '../store/stagedupdates-slice';
import { globalErrorsActions } from '../store/globalerrors-slice';
import { fetchInterventions } from '../store/interventions-actions';
import { fetchRootcodes } from '../store/rootcode-actions';
import { fetchSymptoms } from '../store/symptom-actions';
import { fetchTocs } from '../store/tocs-actions';
import { fetchRailwayPeriods } from '../store/railwayperiods-actions';

import InterventionsTable from '../components/InterventionsTable';
import TableFilterBar from '../components/UI/TableFilterBar';
import FetchDataButton from '../components/UI/FetchDataButton';
import EditIntervention from '../components/EditInterventions/EditIntervention';
import ErrorToast from '../components/UI/ErrorToast';
import TablePopupInfo from '../components/TablePopupInfo';
import ChartCard from '../components/ChartCard';
import HealthBar from '../components/HealthBar';
import { InterventionsTableLoading } from '../components/TableLoading';

import classes from './EngineInterventions.module.css';

import { filterItems, hasWritePermission, customSortValue } from '../components/util/util';

const IS_RELEVANT_OPTIONS = ['YES', 'NO'];

const EngineInterventions = () => {
    const dispatch = useDispatch();

    // set state for intervention currently being edited
    const [IsInterventionVerified, setIsInterventionVerified] = useState(null);
    const [originalInterventionIsRelevant, setOriginalInterventionIsRelevant] = useState(null);
    const [originalInterventionRootCode, setOriginalInterventionRootCode] = useState(null);
    const [originalInterventionSymptom, setOriginalInterventionSymptom] = useState(null);
    const [
        originalInterventionMasterInterventionKey,
        setOriginalInterventionMasterInterventionKey,
    ] = useState(null);
    const [originalInterventionInterventionKey, setOriginalInterventionInterventionKey] =
        useState(null);
    const [verifiedInterventionIsRelevant, setVerifiedInterventionIsRelevant] = useState(null);
    const [verifiedInterventionRootCode, setVerifiedInterventionRootCode] = useState(null);
    const [verifiedInterventionSymptom, setVerifiedInterventionSymptom] = useState(null);
    const [
        verifiedInterventionMasterInterventionKey,
        setVerifiedInterventionMasterInterventionKey,
    ] = useState(null);
    const [verifiedInterventionInterventionKey, setVerifiedInterventionInterventionKey] =
        useState(null);
    const [originalRailwayPeriod, setOriginalRailwayPeriod] = useState(null);

    // set state of drop down values for AutoComplete components from MUI (see https://mui.com/material-ui/react-autocomplete/)
    const [isRelevantDropdownValue, setIsRelevantDropdownValue] = useState('');
    const [rootCodeDropdownValue, setRootCodeDropdownValue] = useState('');
    const [symptomDropdownValue, setSymptomDropdownValue] = useState('');
    const [masterInterventionKeyDropdownValue, setMasterInterventionKeyDropdownValue] =
        useState('');

    // local error states
    const [duplicateEditError, setDuplicateEditError] = useState(null);
    const [duplicateEditErrorMsg, setDuplicateEditErrorMsg] = useState(null);

    // state for clicking on long table values
    const [popupContents, setPopupContents] = useState([]);
    const delayHandler = useRef(null);

    // grab status of whether the edit interventions modal is active
    const isEditingIntervention = useSelector((state) => state.ui.editInterventionIsVisible);

    // grab status of TOC
    const selectedToc = useSelector((state) => state.ui.selectedTOC);

    // grab status of Railway Period
    const selectedPeriod = useSelector((state) => state.ui.selectedPeriod);

    // grab dropdown value of TOC dropdown
    const selectedTOCDropdownValue = useSelector((state) => state.ui.selectedTOCDropdownValue);

    // grab stadropdown valuetus of Railway Period dropdown
    const selectedPeriodDropdownValue = useSelector(
        (state) => state.ui.selectedPeriodDropdownValue
    );

    // grab the interventions and derive the header row of the data
    const interventions = useSelector((state) => state.interventions.interventions);

    const headers =
        interventions === null || interventions.length < 1
            ? null
            : [...Object.keys(interventions[0]), 'Edit'];

    // derive the master_intervention_keys from the interventions
    const masterInterventionKeys = interventions
        ? [...new Set(interventions.map((intervention) => intervention.Intervention_Key))]
        : null;

    // grab the interventions loading status
    const interventionsIsLoading = useSelector((state) => state.ui.interventionsIsLoading);

    // set status of "Load Interventions" button
    const isButtonEnabled = !interventionsIsLoading && selectedTOCDropdownValue;

    // grab the current staged intervention keys
    const stagedUpdates = useSelector((state) => state.stagedUpdates.updates);
    const stagedInterventionKeys = stagedUpdates.map(
        (update) => update.originalInterventionInterventionKey
    );

    const populateRailwayPeriods = () => {
        dispatch(fetchRailwayPeriods(NUM_PERIODS));
    };

    useEffect(() => {
        populateRailwayPeriods();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const railwayPeriods = useSelector((state) => state.railwayPeriods?.railwayPeriods);

    const selectAllOption = { value: '', label: 'All periods' };
    const railwayPeriodsValues = useMemo(
        () =>
            railwayPeriods
                ? railwayPeriods
                      .map((period) => ({ value: period.label, label: period.label }))
                      .reverse()
                : [],
        [railwayPeriods]
    );

    // wrap action creator in function, load, then grab the root codes
    const populateRootcodes = () => {
        dispatch(
            fetchRootcodes(
                `${process.env.REACT_APP_API_URL}/codes?` +
                    new URLSearchParams({
                        codeType: 'root',
                    })
            )
        );
    };

    useEffect(() => {
        populateRootcodes();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const rootcodes = useSelector((state) => state.rootcodes.rootcodes);
    let rootcodeValues = useMemo(
        () => (rootcodes ? rootcodes.map((rootcode) => rootcode.value) : []),
        [rootcodes]
    );

    // Legends object for root codes
    const rootcodesLegends = useMemo(
        () =>
            rootcodes
                ? rootcodes.reduce((acc, rootcode) => {
                      return {
                          [INTERVENTION_PARAMS.P_ROOT_CODE]: {
                              ...acc[INTERVENTION_PARAMS.P_ROOT_CODE],
                              [rootcode.value]: rootcode.label.replace(/ +(?= )/g, ''), // remove extra spaces from label
                          },
                      };
                  }, {})
                : null,
        [rootcodes]
    );

    // wrap action creator in function, load, then grab the symptoms
    // http request to go and fetch symptoms data via Redux action
    const populateSymptoms = () => {
        dispatch(
            fetchSymptoms(
                `${process.env.REACT_APP_API_URL}/codes?` +
                    new URLSearchParams({
                        codeType: 'symptom',
                    })
            )
        );
    };

    useEffect(() => {
        populateSymptoms();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const symptoms = useSelector((state) => state.symptoms.symptoms);
    let symptomValues = useMemo(
        () => (symptoms ? symptoms.map((symptom) => symptom.value) : []),
        [symptoms]
    );

    // wrap action creator in function, load, then grab the root codes
    const populateTocs = () => {
        dispatch(fetchTocs(`${process.env.REACT_APP_API_URL}/tocs`));
    };

    useEffect(() => {
        populateTocs();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const tocs = useSelector((state) => state.tocs.tocs);
    let tocValues = useMemo(() => (tocs ? tocs.map((toc) => toc.name_short) : []), [tocs]);

    // Railway Period dropdown field value handler (as per MUI)
    const rpDropdownItemHandler = (newValue) => {
        dispatch(uiActions.setSelectedPeriodDropdownValue(newValue)); // using material UI
    };

    // TOC dropdown field value handler (as per MUI)
    const tocDropdownItemHandler = (newValue) => {
        dispatch(uiActions.setSelectedTOCDropdownValue(newValue)); // using material UI
    };

    // reset dropdown value to the active TOC and Period to avoid misleading worker
    useEffect(() => {
        tocDropdownItemHandler(selectedToc || null);
        rpDropdownItemHandler(selectedPeriod || null);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // hide the edit interventions modal handler
    const hideEditInterventionHandler = () => {
        dispatch(uiActions.hideEditIntervention());
    };

    // show the edit interventions modal handler
    const showInterventionHandler = (event) => {
        const intervention = interventions.filter(
            (intervention) => intervention.Intervention_Key === event.target.id
        )[0];

        // only show edit interventions if Intervention is unique (i.e we are not trying to edit it twice in once batch)
        if (stagedInterventionKeys.includes(intervention.Intervention_Key)) {
            setDuplicateEditError('Intervention Already Staged');
            setDuplicateEditErrorMsg('Can only edit unique interventions');
            return;
        }
        if (stagedInterventionKeys.length >= 25) {
            dispatch(
                globalErrorsActions.showPopupError({
                    error: 'Basket Full',
                    errorMsg:
                        'Maximum basket size exceeded: Please submit the records in the basket before adding any more.',
                })
            );
            return;
        }
        setOriginalRailwayPeriod(intervention.Railway_Period);
        setIsInterventionVerified(intervention.Is_Verified === 'true' ? true : false);
        setOriginalInterventionIsRelevant(intervention.Is_Relevant === 'true' ? 'YES' : 'NO');
        setOriginalInterventionRootCode(intervention.P_Root_Code);
        setOriginalInterventionSymptom(intervention.P_Symptom);
        setOriginalInterventionMasterInterventionKey(intervention.Master_Intervention_Key);
        setOriginalInterventionInterventionKey(intervention.Intervention_Key);
        setVerifiedInterventionIsRelevant(intervention.Is_Relevant === 'true' ? 'YES' : 'NO');
        setVerifiedInterventionRootCode(intervention.P_Root_Code);
        setVerifiedInterventionSymptom(intervention.P_Symptom);
        setVerifiedInterventionMasterInterventionKey(intervention.Master_Intervention_Key);
        setVerifiedInterventionInterventionKey(intervention.Intervention_Key);
        setIsRelevantDropdownValue(intervention.Is_Relevant === 'true' ? 'YES' : 'NO');
        setRootCodeDropdownValue(intervention.P_Root_Code);
        setSymptomDropdownValue(intervention.P_Symptom);
        setMasterInterventionKeyDropdownValue(intervention.Master_Intervention_Key);
        dispatch(uiActions.showEditIntervention());
    };

    const selectIsRelevantHandler = (newValue) => {
        setVerifiedInterventionIsRelevant(newValue);
    };

    const selectRootCodeHandler = (newValue) => {
        setVerifiedInterventionRootCode(newValue);
    };

    const selectSymptomHandler = (newValue) => {
        setVerifiedInterventionSymptom(newValue);
    };

    const selectMasterInterventionKeyHandler = (newValue) => {
        setVerifiedInterventionMasterInterventionKey(newValue);
    };

    const isRelevantDropdownHandler = (newValue) => {
        setIsRelevantDropdownValue(newValue);
    };

    const rootCodeDropdownHandler = (newValue) => {
        setRootCodeDropdownValue(newValue);
    };

    const symptomDropdownHandler = (newValue) => {
        setSymptomDropdownValue(newValue);
    };

    const masterInterventionKeyDropdownHandler = (newValue) => {
        setMasterInterventionKeyDropdownValue(newValue);
    };

    // Specifies if all internal table filters should reset
    const resetTableFilters = useRef(false);
    // Interventions initial filter
    const tableInitialFilter = useRef({
        [INTERVENTION_PARAMS.RAILWAY_PERIOD]: {
            query: selectedPeriodDropdownValue?.value,
            match: 'EQUALS',
        },
    });

    // http request to go and fetch interventions data via Redux action
    const populateTable = () => {
        dispatch(
            fetchInterventions(
                selectedTOCDropdownValue,
                'Date',
                'DESC',
                `${process.env.REACT_APP_API_URL}/records`
            )
        );
        tableInitialFilter.current = {
            [INTERVENTION_PARAMS.RAILWAY_PERIOD]: {
                query: selectedPeriodDropdownValue?.value,
                match: 'EQUALS',
            },
        };

        resetTableFilters.current =
            (!!selectedToc && selectedToc !== '' && selectedTOCDropdownValue !== selectedToc) ||
            (!!selectedPeriod?.value &&
                selectedPeriodDropdownValue?.value !== selectedPeriod?.value);
    };

    const filteredItems = useMemo(
        () =>
            tableInitialFilter.current
                ? filterItems(interventions, tableInitialFilter.current)
                : interventions,
        [interventions]
    );

    useEffect(() => {
        dispatch(uiActions.setSelectedPeriod(selectedPeriodDropdownValue));
        resetTableFilters.current = false;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [interventions]);

    const submitUpdateForm = () => {
        const updateData = {
            originalInterventionInterventionKey,
            originalInterventionMasterInterventionKey,
            originalInterventionRootCode,
            originalInterventionSymptom,
            originalInterventionIsRelevant,
            verifiedInterventionInterventionKey,
            verifiedInterventionMasterInterventionKey,
            verifiedInterventionRootCode,
            verifiedInterventionSymptom,
            verifiedInterventionIsRelevant,
            originalRailwayPeriod,
        };

        dispatch(stagedUpdatesActions.addUpdate(updateData));
        hideEditInterventionHandler();
    };

    const interventionsTableRef = useRef();
    const interventionKeyRef = useRef(null);
    const [isPopupClosed, setIsPopupClosed] = useState(null);

    const setInfoContent = (content, interventionKey) => {
        const title = content.header;
        const description = content.description;

        setIsPopupClosed(false);

        if (interventionKeyRef.current !== interventionKey) {
            setPopupContents([{ title, description }]);
            interventionKeyRef.current = interventionKey;
            return;
        }

        const contentExists = popupContents.some((content) => content.title === title);
        delayHandler.current =
            !contentExists && setPopupContents([...popupContents, { title, description }]);
    };

    const closePopup = () => {
        clearTimeout(delayHandler.current);
        setPopupContents([]);
        setIsPopupClosed(true);
    };

    // grab user profile data
    const profile = useSelector((state) => state.profile.profile);
    // extract user roles from profile object
    const userCanWrite = useMemo(
        () => hasWritePermission(profile, selectedToc),
        [profile, selectedToc]
    );

    return (
        <>
            <HealthBar />
            <div className={classes.wrapper}>
                <div className={classes['table_filter_wrapper']}>
                    <TableFilterBar>
                        <Autocomplete
                            disablePortal
                            id='combo-box-toc'
                            disableClearable={true}
                            options={tocValues}
                            value={selectedTOCDropdownValue}
                            onChange={(e, newVal) => tocDropdownItemHandler(newVal)}
                            sx={{ width: 150, marginRight: '10px' }}
                            renderInput={(params) => <TextField {...params} label='TOC' />}
                            size='small'
                        />
                        <Autocomplete
                            disablePortal
                            id='combo-box-period'
                            disableClearable={true}
                            options={[selectAllOption, ...railwayPeriodsValues]}
                            value={selectedPeriodDropdownValue || selectAllOption}
                            onChange={(e, newVal) => rpDropdownItemHandler(newVal)}
                            sx={{ width: 150 }}
                            renderInput={(params) => <TextField {...params} label='Period' />}
                            size='small'
                            isOptionEqualToValue={(option, value) => option.label === value.label}
                        />
                        <FetchDataButton onClick={populateTable} disabled={!isButtonEnabled} />
                    </TableFilterBar>
                    {filteredItems?.length > 0 && (
                        <ChartCard
                            data={filteredItems}
                            keyValue='Intervention_Key'
                            defaultValues={[
                                'Maintenance',
                                'Performance',
                                'Third Party Maintenance',
                            ]}
                        />
                    )}
                </div>
                <div className={classes['table-wrapper']}>
                    {filteredItems?.length > 0 && (
                        <InterventionsTable
                            tableRef={interventionsTableRef}
                            onEdit={showInterventionHandler}
                            headers={headers}
                            data={filteredItems}
                            onCellClick={setInfoContent}
                            resetActiveRow={isPopupClosed}
                            userCanWrite={userCanWrite}
                            exclusions={[
                                'Root_Code_Secondary_Probability',
                                'Root_Code_Tertiary_Probability',
                                'Symptom_Code_Probability',
                                'Intervention_ID',
                                'Intervention_Type',
                            ]}
                            legends={rootcodesLegends}
                            shouldResetFilters={resetTableFilters.current}
                            customSortValue={customSortValue}
                        />
                    )}
                    <InterventionsTableLoading
                        noData={!filteredItems || filteredItems.length === 0}
                    />
                </div>
                {popupContents.length > 0 && (
                    <TablePopupInfo closePopup={closePopup} popupContents={popupContents} />
                )}
            </div>

            {isEditingIntervention && rootcodes && symptoms && (
                <EditIntervention
                    onClose={hideEditInterventionHandler}
                    IsInterventionVerified={IsInterventionVerified}
                    masterInterventionKeys={masterInterventionKeys}
                    rootcodes={rootcodeValues}
                    symptoms={symptomValues}
                    isRelevant={IS_RELEVANT_OPTIONS}
                    verifiedInterventionIsRelevant={verifiedInterventionIsRelevant}
                    originalInterventionIsRelevant={originalInterventionIsRelevant}
                    isRelevantHandler={selectIsRelevantHandler}
                    verifiedInterventionRootCode={verifiedInterventionRootCode}
                    originalInterventionRootCode={originalInterventionRootCode}
                    rootcodeHandler={selectRootCodeHandler}
                    verifiedInterventionSymptom={verifiedInterventionSymptom}
                    originalInterventionSymptom={originalInterventionSymptom}
                    symptomHandler={selectSymptomHandler}
                    verifiedInterventionMasterInterventionKey={
                        verifiedInterventionMasterInterventionKey
                    }
                    originalInterventionMasterInterventionKey={
                        originalInterventionMasterInterventionKey
                    }
                    masterInterventionKeyHandler={selectMasterInterventionKeyHandler}
                    originalInterventionInterventionKey={originalInterventionInterventionKey}
                    masterInterventionKeyDropdownValue={masterInterventionKeyDropdownValue}
                    rootCodeDropdownValue={rootCodeDropdownValue}
                    symptomDropdownValue={symptomDropdownValue}
                    isRelevantDropdownValue={isRelevantDropdownValue}
                    masterInterventionKeyDropdownHandler={masterInterventionKeyDropdownHandler}
                    rootCodeDropdownHandler={rootCodeDropdownHandler}
                    symptomDropdownHandler={symptomDropdownHandler}
                    isRelevantDropdownHandler={isRelevantDropdownHandler}
                    onSubmit={submitUpdateForm}
                    legends={rootcodesLegends}
                />
            )}
            {duplicateEditError && (
                <div className={classes.errors}>
                    <ErrorToast
                        error={duplicateEditError}
                        errorMsg={duplicateEditErrorMsg}
                        onClose={() => setDuplicateEditError(null)}
                    />
                </div>
            )}
        </>
    );
};

export default EngineInterventions;
