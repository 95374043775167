import classes from './HealthBar.module.css';
import { FETCH_STATUS } from './util/constants';
import { useSelector } from 'react-redux';
import { Tooltip } from '@mui/material';

const HealthBar = () => {
    // grab interventions fetch success status
    const interventionsFetchSuccess = useSelector((state) => state.ui.interventionsFetchSuccess);

    // grab the interventions loading status
    const interventionsIsLoading = useSelector((state) => state.ui.interventionsIsLoading);

    // grab the root codes loading status
    const rootCodesIsLoading = useSelector((state) => state.ui.rootCodesIsLoading);

    // grab the root codes fetch success status
    const rootCodesFetchSuccess = useSelector((state) => state.ui.rootCodesFetchSuccess);

    // grab the symptom loading status
    const symptomsIsLoading = useSelector((state) => state.ui.symptomsIsLoading);

    // grab the symptom fetch success status
    const symptomsFetchSuccess = useSelector((state) => state.ui.symptomsFetchSuccess);

    // grab the tocs loading status
    const tocsIsLoading = useSelector((state) => state.ui.tocsIsLoading);

    // grab the tocs fetch success status
    const tocsFetchSuccess = useSelector((state) => state.ui.tocsFetchSuccess);

    const railwayPeriodsStatus = useSelector((state) => state.ui.railwayPeriodsFetchStatus);

    return (
        <div className={classes.healthbar}>
            <div>Connection Status</div>
            <div className={classes['healthbar-wrapper']}>
                <HealthStep
                    title='Root Codes Retrieval Status'
                    isLoading={rootCodesIsLoading}
                    isSuccess={rootCodesFetchSuccess}
                    isError={rootCodesFetchSuccess === false && !rootCodesIsLoading}
                />

                <HealthStep
                    title='Symptom Retrieval Status'
                    isLoading={symptomsIsLoading}
                    isSuccess={symptomsFetchSuccess}
                    isError={symptomsFetchSuccess === false && !symptomsIsLoading}
                />

                <HealthStep
                    title='TOC Retrieval Status'
                    isLoading={tocsIsLoading}
                    isSuccess={tocsFetchSuccess}
                    isError={tocsFetchSuccess === false && !tocsIsLoading}
                />

                <HealthStep
                    title='Period Retrieval Status'
                    isLoading={railwayPeriodsStatus === FETCH_STATUS.LOADING}
                    isSuccess={railwayPeriodsStatus === FETCH_STATUS.SUCCESS}
                    isError={railwayPeriodsStatus === FETCH_STATUS.ERROR}
                />

                <HealthStep
                    title='Interventions Retrieval Status'
                    isLoading={interventionsIsLoading}
                    isSuccess={interventionsFetchSuccess}
                    isError={interventionsFetchSuccess === false && !interventionsIsLoading}
                />
            </div>
        </div>
    );
};

export const JobsHealthBar = () => {
    // grab the Jobs loading status
    const jobStatusIsLoading = useSelector((state) => state.ui.jobsIsLoading);

    // grab the Jobs fetch success status
    const jobStatusFetchSuccess = useSelector((state) => state.ui.jobsFetchSuccess);
    return (
        <div className={classes.healthbar}>
            <div>Connection Status</div>
            <div className={classes['healthbar-wrapper']}>
                <HealthStep
                    title='Jobs Retrieval Status'
                    isLoading={jobStatusIsLoading}
                    isSuccess={jobStatusFetchSuccess}
                    isError={jobStatusFetchSuccess === false && !jobStatusIsLoading}
                />
            </div>
        </div>
    );
};

const HealthStep = ({ title, isLoading, isSuccess, isError }) => {
    const status = isLoading
        ? { label: '(Loading)', class: classes[`is-loading`] }
        : isSuccess
          ? { label: '(Success)', class: classes[`is-success`] }
          : isError
            ? { label: '(Error)', class: classes[`is-error`] }
            : { label: '', class: '' };

    return (
        <Tooltip title={`${title} ${status.label}`}>
            <div className={`${classes.step} ${status.class}`} />
        </Tooltip>
    );
};

export default HealthBar;
