import classes from './ToastWidget.module.css';

import { IconContext } from 'react-icons';
import { BsCheckCircleFill, BsFillXCircleFill, BsFillExclamationCircleFill } from 'react-icons/bs';

const LoadStatusWidget = (props) => {
    let title = '';
    if (props.isSuccess && !props.isLoading) {
        title = 'Success';
    } else if (props.isSuccess === false && !props.isLoading) {
        title = 'Failed';
    } else if (props.isLoading) {
        title = 'Loading';
    } else {
        title = 'No Data Loaded';
    }

    return (
        <div className={classes.widget}>
            {props.isLoading && <div className={classes.loader}></div>}
            {!props.isLoading && props.isSuccess && (
                <IconContext.Provider value={{ color: 'green', size: '2rem' }}>
                    <div className={classes['icon-container']}>
                        <BsCheckCircleFill />
                    </div>
                </IconContext.Provider>
            )}

            {!props.isLoading && props.isSuccess === false && (
                <IconContext.Provider value={{ color: 'red', size: '2rem' }}>
                    <div className={classes['icon-container']}>
                        <BsFillXCircleFill />
                    </div>
                </IconContext.Provider>
            )}

            {!props.isLoading && props.isSuccess === null && (
                <IconContext.Provider value={{ color: 'orange', size: '2rem' }}>
                    <div className={classes['icon-container']}>
                        <BsFillExclamationCircleFill />
                    </div>
                </IconContext.Provider>
            )}

            <div className={classes.text}>
                <div className={classes.title}>{title}</div>
                <div className={classes.message}>{props.message}</div>
            </div>
        </div>
    );
};

export default LoadStatusWidget;
