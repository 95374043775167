import { Button as MuiButton } from '@mui/material';

const Button = ({ children, id = null, disabled = false, ...props }) => {
    return (
        <MuiButton
            id={id}
            sx={[
                {
                    backgroundColor: 'var(--colour-porterbrook-blue)',
                    textTransform: 'none',
                },
                {
                    '&:hover': { backgroundColor: 'var(--colour-dark-blue)' },
                },
            ]}
            variant='contained'
            disabled={disabled}
            {...props}>
            {children}
        </MuiButton>
    );
};

export default Button;
