import classes from './Home.module.css';
import Instructions from '../components/Instructions';

const HomePage = () => {
    return (
        <div className={classes['wrapper']}>
            <h1>Fleet Reliability Dashboard</h1> {/* replace with Hero*/}
            <Instructions />
        </div>
    );
};

export default HomePage;
