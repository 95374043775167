import { Form, useActionData, useNavigation } from 'react-router-dom';

import classes from './AuthForm.module.css';

function AuthForm() {
    const data = useActionData();
    const navigation = useNavigation();

    const isSubmitting = navigation.state === 'submitting';

    return (
        <>
            <Form method='post' className={classes.form}>
                <h1>Log In</h1>
                {data && data.detail && (
                    <ul>
                        {Object.values(data.detail).map((err) => (
                            <li key={err}>{err.msg}</li>
                        ))}
                    </ul>
                )}
                <p>
                    <label htmlFor='username'>Email</label>
                    <input id='username' type='email' name='username' autoComplete='on' required />
                </p>
                <p>
                    <label htmlFor='password'>Password</label>
                    <input id='password' type='password' name='password' required />
                </p>
                <div className={classes.actions}>
                    <button disabled={isSubmitting}>
                        {isSubmitting ? 'Submitting...' : 'Submit'}
                    </button>
                </div>
            </Form>
        </>
    );
}

export default AuthForm;
