import { useState, useMemo, useCallback } from 'react';
import { sortItems, filterItems } from '../components/util/util';
import { SORT_DIRECTION } from '../components/util/constants';

const useSortAndFilter = ({ items }) => {
    const [filterOptions, setFilterOptions] = useState(null);
    const setFilter = useCallback((values) => {
        if (!values) {
            setFilterOptions(null);
            return;
        }
        const { key, query, match } = values;
        if (!key) {
            return;
        }
        setFilterOptions((previousFilters) => ({
            ...previousFilters,
            [key]: query ? { query: query, match } : null,
        }));
    }, []);

    const [sortOptions, setSortOptions] = useState({
        key: null,
        direction: SORT_DIRECTION.ASC,
        sortValue: null,
    });

    const toggleDirection = (key) => {
        if (!sortOptions) {
            return;
        }
        setSortOptions((prevOptions) => ({
            ...prevOptions,
            direction:
                prevOptions.key === key && prevOptions.direction === SORT_DIRECTION.ASC
                    ? SORT_DIRECTION.DESC
                    : SORT_DIRECTION.ASC,
        }));
    };

    const isASC = (key) => {
        return sortOptions.direction === SORT_DIRECTION.ASC || sortOptions.key !== key
            ? true
            : false;
    };

    const filteredAndSorted = useMemo(() => {
        if (!sortOptions && !filterOptions) return items;
        let processedItems = [...items];
        if (sortOptions) {
            processedItems = sortItems(
                processedItems,
                sortOptions.direction,
                sortOptions.key,
                sortOptions.sortValue
            );
        }
        if (filterOptions) {
            processedItems = filterItems(processedItems, filterOptions);
        }

        return processedItems;
    }, [sortOptions, filterOptions, items]);

    return {
        filteredAndSorted,
        setFilter,
        filterValues: filterOptions,
        toggleDirection,
        setSortOptions,
        isASC,
    };
};

export default useSortAndFilter;
