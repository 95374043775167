import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import RootLayout from './components/Layout/Root';
import ErrorPage from './pages/Error';
import HomePage from './pages/Home';
import EngineInterventions from './pages/EngineInterventions';
import Profile from './pages/Profile';
import Jobs from './pages/Jobs';
import { action as logoutAction } from './pages/Logout';
import { loader as tokenLoader } from './components/util/auth';
import AuthenticationPage, { action as authAction } from './pages/Auth';

// Set up the Router
const router = createBrowserRouter([
    {
        path: 'auth',
        element: <AuthenticationPage data-testid='auth-page' />,
        action: authAction,
    },
    {
        path: '/',
        element: <RootLayout data-testid='root' />,
        errorElement: <ErrorPage />,
        id: 'root',
        loader: tokenLoader,
        children: [
            {
                index: true,
                element: <HomePage data-testid='home-page' />,
                // loader: tokenLoader,
            },
            {
                path: 'engineInterventions',
                element: <EngineInterventions />,
                errorElement: <ErrorPage />,
                // loader: tokenLoader,
            },
            {
                path: 'user',
                element: <Profile />,
                errorElement: <ErrorPage />,
                // loader: tokenLoader,
            },
            {
                path: 'jobs',
                element: <Jobs />,
                errorElement: <ErrorPage />,
                // loader: tokenLoader,
            },
        ],
    },
    {
        path: 'logout',
        action: logoutAction,
    },
]);

function App() {
    return <RouterProvider router={router} />;
}

export default App;
