import { createSlice } from '@reduxjs/toolkit';

const rootcodeSlice = createSlice({
    name: 'rootcodes',
    initialState: {
        rootcodes: null,
    },
    reducers: {
        populateRootcodes(state, action) {
            state.rootcodes = action.payload; // payload is a field generated by Redux-Toolkit
        },
    },
});

export const rootcodeActions = rootcodeSlice.actions;

export default rootcodeSlice;
