import { useEffect, useState } from 'react';
import { Navigate, json, redirect } from 'react-router-dom';

import { validateAuthToken } from '../components/util/auth';

import AuthForm from '../components/AuthForm';
import ErrorToast from '../components/UI/ErrorToast';

import classes from './Auth.module.css';

import store from '../store/index';
import { globalErrorsActions } from '../store/globalerrors-slice';
import { useSelector, useDispatch } from 'react-redux';

const AuthenticationPage = () => {
    const dispatch = useDispatch();

    const authErrors = useSelector((state) => state.globalErrors.errors);

    const clearError = (id) => {
        dispatch(globalErrorsActions.clearError(id));
    };

    const [isAuthenticated, setIsAuthenticated] = useState(false);

    useEffect(() => {
        const validToken = validateAuthToken();
        setIsAuthenticated(validToken);
    }, []);

    if (isAuthenticated) {
        return <Navigate to='/' replace />;
    }

    return (
        <main className={classes.main}>
            <AuthForm />
            {authErrors && (
                <div className={classes.errors}>
                    {authErrors.map((error) => (
                        <ErrorToast
                            key={error.id}
                            error={error.error}
                            errorMsg={error.errorMsg}
                            onClose={() => clearError(error.id)}
                        />
                    ))}
                </div>
            )}
        </main>
    );
};

export default AuthenticationPage;

export const action = async ({ request }) => {
    // triggered when AuthForm is submitted with non-GET req.
    const data = await request.formData();
    const authData = {
        username: data.get('username'),
        password: data.get('password'),
        grant_type: 'password',
    };

    try {
        // TODO: DRY code by using functins from auth.js
        const response = await fetch(`${process.env.REACT_APP_API_URL}/auth/token`, {
            method: 'POST',
            headers: {
                accept: 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: new URLSearchParams(authData),
        });

        const resData = await response.json();

        if (response.status === 401) {
            // console.log(resData)// {message: 'Authentication failed. Either the provided email or password were incorrect'}
            store.dispatch(
                globalErrorsActions.addError({
                    error: 'Login Failed',
                    errorMsg: resData.message,
                })
            );
            return resData; // TODO: Check this response object is what should be returned
        }
        if (response.status === 422) {
            // console.log(resData) //{"message":"Validation error occurred","errors":{"username":["Field required"]}}
            store.dispatch(
                globalErrorsActions.addError({
                    error: 'Login Failed',
                    errorMsg: resData.message,
                })
            );
            return resData;
        }

        if (!response.ok) {
            // TODO: Ask backend team to create another exception to test this
            store.dispatch(
                globalErrorsActions.addError({
                    error: 'Login Failed',
                    errorMsg: 'Unknown authentication error occurred. Contact support.',
                })
            );
            throw json({ message: 'Could not authenticate user.' }, { status: 500 });
        }

        const token = resData.access_token;
        const refreshToken = resData.refresh_token;

        localStorage.setItem('frp_token', token);
        localStorage.setItem('frp_refresh_token', refreshToken);

        const expiration = new Date();
        expiration.setHours(expiration.getHours() + 1);

        localStorage.setItem('frp_token_expiration', expiration.toISOString());

        return redirect('/');
    } catch {
        // eslint-disable-next-line no-console
        console.log('Code A1: Unhandled exception occurred');
        store.dispatch(
            globalErrorsActions.addError({
                error: 'Login Failed',
                errorMsg: 'Unknown authentication error occurred (Code A1). Contact support.',
            })
        );
        return null;
    }
};
