import { prepareAuthToken } from '../components/util/auth';
import { jobActions } from './jobstatus-slice';
import { uiActions } from './ui-slice';

export const fetchJobs = (urlRoute) => {
    return async (dispatch) => {
        const fetchData = async () => {
            const token = await prepareAuthToken();

            const response = await fetch(urlRoute, {
                headers: {
                    Authorization: 'Bearer ' + token,
                },
            });

            if (response.status === 401) {
                // Unauthorised
                throw new Error(response.statusText);
            }

            if (response.status === 422) {
                // Validation error
                throw new Error(response.message);
            }

            if (response.status === 500) {
                // Internal server error
                throw new Error(response.message);
            }

            if (!response.ok) {
                throw new Error('Failed to fetch data');
            }

            const data = await response.json();
            return data;
        };

        try {
            dispatch(uiActions.setJobLoadingStatus(true));
            const jobs = await fetchData(); // carry out http request prior to dispatching an action
            dispatch(uiActions.setJobLoadingStatus(false));
            dispatch(uiActions.setJobFetchSuccess(true));
            dispatch(jobActions.populateJobs(jobs)); // argument gets passed to reducer as 'payload'
        } catch (error) {
            dispatch(uiActions.setJobLoadingStatus(false));
            dispatch(uiActions.setJobFetchSuccess(false));
        }
    };
};
