import { Fragment, useState, useRef } from 'react';
import classes from './TablePopupInfo.module.css';

import Draggable from 'react-draggable';

import Button from './UI/Button';
import DragHandleIcon from '@mui/icons-material/DragHandle';

const TablePopupInfo = ({ popupContents, closePopup }) => {
    const draggablePopupRef = useRef();
    const [isDragging, setIsDragging] = useState(false);

    const dragBondaries = {
        left: -(window.innerWidth - 50),
        top: 0,
        right: draggablePopupRef.current?.clientWidth - 50,
        bottom: window.innerHeight - 50,
    };
    return (
        <>
            <Draggable
                handle={`.${classes['drag-handle']}`}
                onDrag={() => setIsDragging(true)}
                onStop={() => setIsDragging(false)}
                nodeRef={draggablePopupRef}
                bounds={draggablePopupRef && dragBondaries}>
                <div ref={draggablePopupRef} className={classes['popup_info-wrapper']} open={true}>
                    <div className={classes['drag-handle']} id='drag-handle'>
                        <DragHandleIcon fontSize='large' color='var(--colour-porterbrook-blue)' />
                    </div>
                    <div className={classes.popup_content}>
                        {popupContents.map((content, i) => {
                            return (
                                <Fragment key={i}>
                                    <section className={classes.content}>
                                        <h5>{content.title}:</h5>
                                        <p aria-description={content.description}>
                                            {content.description}
                                        </p>
                                    </section>
                                    <hr />
                                </Fragment>
                            );
                        })}
                    </div>
                    <Button className={classes.close_btn} onClick={closePopup}>
                        Close
                    </Button>
                </div>
            </Draggable>
            {isDragging && <div className={classes['backdrop-layer']}></div>}
        </>
    );
};

export default TablePopupInfo;
