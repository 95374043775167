import { useEffect } from 'react';
import classes from './Jobs.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { fetchJobs } from '../store/jobstatus-actions';

import { INTERVENTION_PARAMS as I_P } from '../components/util/constants';

import { Button } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import JobsTable from '../components/JobsTable';
import { JobsHealthBar } from '../components/HealthBar';
import { JobsTableLoading } from '../components/TableLoading';

const Jobs = () => {
    const dispatch = useDispatch();

    // wrap action creator in function, load, then grab the job status'
    const populateJobStatus = () => {
        dispatch(fetchJobs(`${process.env.REACT_APP_API_URL}/jobs`));
    };

    useEffect(() => {
        populateJobStatus();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // grab the jobs data
    const jobData = useSelector((state) => state.jobs.jobs);

    // set up the table header
    const jobHeaders =
        jobData === null
            ? null
            : [
                  I_P.INTERVENTION_KEY,
                  I_P.MASTER_INTERVENTION_KEY,
                  I_P.P_ROOT_CODE,
                  I_P.P_SYMPTOM,
                  I_P.IS_RELEVANT,
                  I_P.RAILWAY_PERIOD,
                  I_P.JOB_STATUS,
                  I_P.CREATED_TIME,
              ];

    return (
        <div className={classes['jobs-wrapper']}>
            <JobsHealthBar />
            <div className={classes['title-wrapper']}>
                <h1 className='title'>Update Job History</h1>
                <Button
                    onClick={() => populateJobStatus()}
                    variant='contained'
                    color='success'
                    startIcon={
                        <RefreshIcon fontSize='large' sx={{ color: 'white' }} color='inherit' />
                    }>
                    Refresh data
                </Button>
            </div>

            <div className={classes['table-wrapper']}>
                {jobData !== null && jobData.length > 0 && (
                    <JobsTable headers={jobHeaders} data={jobData} />
                )}
                <JobsTableLoading noData={!jobData || jobData.length === 0} />
            </div>
            {jobData === null || jobData.length === 0 ? <p>No Jobs Available</p> : null}
        </div>
    );
};

export default Jobs;
