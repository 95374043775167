import { useEffect, useRef } from 'react';

import { INTERVENTION_PARAMS as I_P } from '../util/constants';

import Autocomplete from '@mui/material/Autocomplete';
import classes from './EditInterventionForm.module.css';
import Button from '../UI/Button';

import { Box, TextField } from '@mui/material';

const EditInterventionForm = ({ legends, ...props }) => {
    //is form valid to submit
    const formIsValid =
        props.originalInterventionMasterInterventionKey !==
            props.verifiedInterventionMasterInterventionKey ||
        props.originalInterventionRootCode !== props.verifiedInterventionRootCode ||
        props.originalInterventionSymptom !== props.verifiedInterventionSymptom ||
        props.originalInterventionIsRelevant !== props.verifiedInterventionIsRelevant;

    const isAnyValueEmpty = useRef(false);
    const submitHandler = (event) => {
        event.preventDefault();
        isAnyValueEmpty.current === false && props.onSubmit();
    };

    const onHighlightChange = (e, option) => {
        isAnyValueEmpty.current = !option ? true : false;
    };

    const resetAnyValueEmpty = () => {
        isAnyValueEmpty.current = false;
    };

    const rootCodeFilterOptions = (options, state) => {
        const queryWords = state.inputValue.toLowerCase().split(' ');

        return options.filter((option) => {
            const legendValue = legends?.[I_P.P_ROOT_CODE]?.[option]?.toLowerCase();
            const optionValue = `${option.toLowerCase()} ${legendValue ? `(${legendValue})` : ''}`;

            return queryWords.every((word) => optionValue.includes(word));
        });
    };

    const rootCodeRenderOption = ({ key, ...props }, option) => {
        const legendValue = legends?.[I_P.P_ROOT_CODE]?.[option];
        return (
            <Box key={key} {...props}>
                <span>
                    <b>{option}</b>
                    {legendValue ? ` (${legendValue})` : ''}
                </span>
            </Box>
        );
    };

    useEffect(() => {
        if (!props.verifiedInterventionMasterInterventionKey) {
            props.masterInterventionKeyHandler(props.originalInterventionInterventionKey);
        }
    }, [props]);

    return (
        <Box component='form' onSubmit={submitHandler}>
            <div className={classes['control-group']}>
                <h2 style={{ textAlign: 'center' }}>{props.currentInterventionKey}</h2>
                <div className={classes['form-control']}>
                    <p>Intervention is Relevant? </p>
                    <Autocomplete
                        disablePortal
                        id='combo-box-isrelevant'
                        disableClearable={true}
                        options={props.isRelevant}
                        inputValue={props.verifiedInterventionIsRelevant}
                        onInputChange={(e, newVal) => props.isRelevantHandler(newVal)}
                        value={props.isRelevantDropdownValue}
                        onChange={(e, newVal) => props.isRelevantDropdownHandler(newVal)}
                        sx={{ width: 250 }}
                        renderInput={(params) => <TextField {...params} label='Relevant' />}
                        onHighlightChange={onHighlightChange}
                        onClose={resetAnyValueEmpty}
                    />
                </div>
                <div className={classes['form-control']}>
                    <p>Master Intervention Key: </p>
                    <Autocomplete
                        disablePortal
                        id='combo-box-masterinterventionkey'
                        disableClearable={true}
                        options={props.masterInterventionKeys}
                        inputValue={
                            props.verifiedInterventionMasterInterventionKey ||
                            props.originalInterventionInterventionKey
                        }
                        onInputChange={(e, newVal) => props.masterInterventionKeyHandler(newVal)}
                        value={
                            props.masterInterventionKeyDropdownValue ||
                            props.originalInterventionInterventionKey
                        }
                        onChange={(e, newVal) => props.masterInterventionKeyDropdownHandler(newVal)}
                        sx={{ width: 250 }}
                        renderInput={(params) => (
                            <TextField {...params} label='Master Intervention Key' />
                        )}
                        onHighlightChange={onHighlightChange}
                        onClose={resetAnyValueEmpty}
                    />
                </div>
                <div className={classes['form-control']}>
                    <p>Root Code: </p>
                    <Autocomplete
                        disablePortal
                        id='combo-box-rootcode'
                        disableClearable={true}
                        options={props.rootcodes}
                        renderOption={rootCodeRenderOption}
                        renderInput={(params) => <TextField {...params} label='Root Code' />}
                        inputValue={props.verifiedInterventionRootCode}
                        onInputChange={(e, newVal) => props.rootcodeHandler(newVal)}
                        value={props.rootCodeDropdownValue}
                        onChange={(e, newVal) => props.rootCodeDropdownHandler(newVal)}
                        filterOptions={rootCodeFilterOptions}
                        sx={{ width: 250 }}
                        onHighlightChange={onHighlightChange}
                        onClose={resetAnyValueEmpty}
                    />
                </div>
                <div className={classes['form-control']}>
                    <p>Symptom: </p>
                    <Autocomplete
                        disablePortal
                        id='combo-box-symptom'
                        disableClearable={true}
                        options={props.symptoms}
                        inputValue={props.verifiedInterventionSymptom}
                        onInputChange={(e, newVal) => props.symptomHandler(newVal)}
                        value={props.symptomDropdownValue}
                        onChange={(e, newVal) => props.symptomDropdownHandler(newVal)}
                        sx={{ width: 250 }}
                        renderInput={(params) => <TextField {...params} label='Symptom' />}
                        onHighlightChange={onHighlightChange}
                        onClose={resetAnyValueEmpty}
                    />
                </div>
                <div className='form-actions'>
                    <Button
                        type='submit'
                        className={classes.button}
                        disabled={!formIsValid && props.IsInterventionVerified}>
                        Stage Update
                    </Button>
                </div>
            </div>
        </Box>
    );
};

export default EditInterventionForm;
