import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';

import uiSlice from './ui-slice';
import interventionsSlice from './interventions-slice';
import rootcodeSlice from './rootcode-slice';
import symptomsSlice from './symptom-slice';
import tocsSlice from './tocs-slice';
import profileSlice from './profile-slice';
import jobSlice from './jobstatus-slice';
import stagedUpdatesSlice from './stagedupdates-slice';
import globalErrorsSlice from './globalerrors-slice';
import railwayPeriodsSlice from './railwayperiods-slice';

const combinedReducer = combineReducers({
    ui: uiSlice.reducer,
    interventions: interventionsSlice.reducer,
    rootcodes: rootcodeSlice.reducer,
    symptoms: symptomsSlice.reducer,
    tocs: tocsSlice.reducer,
    profile: profileSlice.reducer,
    jobs: jobSlice.reducer,
    stagedUpdates: stagedUpdatesSlice.reducer,
    globalErrors: globalErrorsSlice.reducer,
    railwayPeriods: railwayPeriodsSlice.reducer,
});

const localStorageMiddleware = (store) => (next) => (action) => {
    const result = next(action);
    const nextState = store.getState().stagedUpdates.updates;
    if (action.type.split('/')[0] === 'stagedUpdates') {
        localStorage.setItem('stagedUpdates', JSON.stringify(nextState));
    }
    return result;
};

const rootReducer = (state, action) => {
    if (action.type === 'ui/logout') {
        // check for action type
        state = undefined;
    }
    return combinedReducer(state, action);
};

const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => [...getDefaultMiddleware(), localStorageMiddleware],
});

// const store = configureStore({ // removed in favour of rootReducer approach
//     reducer: {
//         ui: uiSlice.reducer,
//         interventions: interventionsSlice.reducer,
//         rootcodes: rootcodeSlice.reducer,
//         symptoms: symptomsSlice.reducer,
//         profile: profileSlice.reducer,
//         jobs: jobSlice.reducer,

//     },
// });

export default store;
