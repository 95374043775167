import { useRef, useEffect, useState, useCallback, useMemo } from 'react';
import useSortAndFilter from '../hooks/useSortAndFilter';
import classes from './InterventionsTable.module.css';

import { useVirtualizer, notUndefined } from '@tanstack/react-virtual';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import { IconButton, Popper, Tooltip, styled, tooltipClasses } from '@mui/material';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import CloseIcon from '@mui/icons-material/Close';

import Button from './UI/Button';
import TitleRow from './TitleRow';
import SelectFilter from './SelectFilter';

import { useSelector } from 'react-redux';

const InterventionsTable = ({
    resetActiveRow,
    data,
    userCanWrite,
    onCellClick,
    headers,
    exclusions,
    onEdit,
    legends,
    shouldResetFilters,
    customSortValue,
}) => {
    const stagedUpdates = useSelector((state) => state.stagedUpdates.updates);

    const [activeRow, setActiveRow] = useState(null);

    const handleCellAction = (content, interventionKey) => {
        onCellClick(content, interventionKey);

        if (activeRow !== interventionKey) {
            setActiveRow(interventionKey);
        }
    };

    const { setSortOptions, setFilter, toggleDirection, filteredAndSorted, filterValues } =
        useSortAndFilter({ items: data });

    const sortFilterKey = useRef(null);

    const handleMenuSortAction = (key) => {
        const sortValue = customSortValue(key);
        setSortOptions((previousValue) => ({ ...previousValue, key, sortValue }));
        toggleDirection(key);
    };

    // Externally reset active row from parent
    useEffect(() => {
        if (resetActiveRow) {
            setActiveRow(null);
        }
    }, [resetActiveRow]);

    // Externally reset filters
    useEffect(() => {
        if (shouldResetFilters === true) {
            setFilter(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, setFilter]);

    // Active column header menu
    const [headerEl, setHeader] = useState(null);
    const menuOpen = Boolean(headerEl);

    // Assign table element
    const tableElement = useRef(null);
    const [popoverOpen, setPopoverOpen] = useState(false);

    const closeColumnMenu = () => {
        setHeader(null);
    };

    const handleMenuFilterAction = (e) => {
        setPopoverOpen(true);
        setFilter({ key: sortFilterKey.current, query: e.target.value });
        closeColumnMenu();
    };

    // Handle actions when column menu is open
    const openColumnMenu = (event, key) => {
        setHeader(event.currentTarget.parentNode);
        sortFilterKey.current = key;
        setPopoverOpen(false);
    };
    const handleFilterClick = () => {
        closeColumnMenu();
        setPopoverOpen(true);
    };

    // Virtualizer

    const tableRef = useRef(null);

    const virtualizer = useVirtualizer({
        count: filteredAndSorted.length,
        getScrollElement: useCallback(() => tableRef.current, []),
        estimateSize: useCallback(() => 55, []),
        overscan: useMemo(() => 20, []),
    });

    const interventions = virtualizer.getVirtualItems();

    // Set size of the first and last table tbody elements

    const [before, after] =
        interventions.length > 0
            ? [
                  notUndefined(interventions[0]).start - virtualizer.options.scrollMargin,
                  virtualizer.getTotalSize() -
                      notUndefined(interventions[interventions.length - 1]).end,
              ]
            : [0, 0];

    const headersList = headers.filter((header) => !exclusions.includes(header));

    return (
        <div style={{ height: '100%' }}>
            <div ref={tableRef} className={classes['table-scroll']}>
                <table className={classes.table}>
                    <thead ref={tableElement}>
                        <tr>
                            {headersList.map((header) => {
                                const processedHeader = header.replace(/_/g, ' ');
                                return (
                                    <TitleRow
                                        key={header}
                                        header={processedHeader}
                                        onClick={(e) => openColumnMenu(e, header)}
                                        hideMenu={header === 'Edit'}
                                        showFilter={!!filterValues?.[header]?.query}
                                        clearFilter={() => {
                                            setFilter({
                                                key: header,
                                                query: null,
                                                match: 'CONTAINS',
                                            });
                                        }}
                                    />
                                );
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        {before > 0 && (
                            <tr>
                                <td colSpan={headers.length} style={{ height: before }}></td>
                            </tr>
                        )}
                        {interventions.map((virtualRow, i) => {
                            const intervention = filteredAndSorted[virtualRow.index];
                            const symptomClass =
                                parseFloat(intervention.Symptom_Code_Probability) > 0.9
                                    ? classes.isGood
                                    : '';
                            const rootcodeClass =
                                parseFloat(intervention.Root_Code_Tertiary_Probability) > 0.9
                                    ? classes.isGood
                                    : '';
                            const isVerifiedClass =
                                intervention.Is_Verified === 'true' ? classes.isVerified : '';
                            const activeRowClass =
                                intervention.Intervention_Key === activeRow ? classes.active : '';
                            const isStagedClass = stagedUpdates.some(
                                (item) =>
                                    item.originalInterventionInterventionKey ===
                                    intervention.Intervention_Key
                            )
                                ? classes.isStaged
                                : '';

                            return (
                                <tr
                                    key={`${intervention.Intervention_Key}_${i}`}
                                    className={`${isVerifiedClass} ${activeRowClass} ${isStagedClass}`}
                                    style={{
                                        height: `${virtualRow.size}px`,
                                    }}>
                                    {headersList.map((header) =>
                                        header !== 'Edit' ? (
                                            <td
                                                key={`${header}_${intervention.Intervention_Key}_${i}`}
                                                className={`${header === 'P_Symptom' && symptomClass}
                                                    ${header === 'P_Root_Code' && rootcodeClass} 
                                                    ${classes.has_action}`}
                                                onClick={() =>
                                                    handleCellAction(
                                                        {
                                                            header: header.replace(/_/g, ' '),
                                                            description: `${intervention[header]} 
                                                            ${
                                                                legends?.[header]
                                                                    ? `(${legends[header][intervention[header]]})`
                                                                    : ''
                                                            }`,
                                                        },
                                                        intervention.Intervention_Key
                                                    )
                                                }>
                                                {legends?.[header] ? (
                                                    <LightTooltip
                                                        title={
                                                            legends[header][intervention[header]]
                                                        }>
                                                        <div>{intervention[header]}</div>
                                                    </LightTooltip>
                                                ) : (
                                                    <>{intervention[header]}</>
                                                )}
                                            </td>
                                        ) : (
                                            <td key={`${header}_${intervention.Intervention_Key}`}>
                                                <Tooltip
                                                    title={
                                                        !userCanWrite
                                                            ? "You don't have permission"
                                                            : ''
                                                    }>
                                                    <span>
                                                        <Button
                                                            onClick={onEdit}
                                                            id={intervention.Intervention_Key}
                                                            disabled={!userCanWrite}>
                                                            Edit
                                                        </Button>
                                                    </span>
                                                </Tooltip>
                                            </td>
                                        )
                                    )}
                                </tr>
                            );
                        })}
                        {after > 0 && (
                            <tr>
                                <td colSpan={headers.length} style={{ height: after }} />
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>

            <Menu
                id='column-menu'
                anchorEl={headerEl}
                open={menuOpen}
                onClose={closeColumnMenu}
                sx={{ top: '10px', left: '-10px' }}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                MenuListProps={{ 'aria-labelledby': 'basic-button' }}>
                <MenuItem onClick={handleFilterClick}>
                    <ListItemIcon>
                        <FilterAltIcon fontSize='small' />
                    </ListItemIcon>
                    Filter
                </MenuItem>
                <MenuItem onClick={() => handleMenuSortAction(sortFilterKey.current)}>
                    <ListItemIcon>
                        <SwapVertIcon fontSize='small' />
                    </ListItemIcon>
                    Order
                </MenuItem>
            </Menu>

            <Popper
                placement='bottom-start'
                anchorEl={tableElement.current}
                open={popoverOpen}
                modifiers={[{ name: 'flip', enabled: false }]}>
                <div className={classes['filter-wrapper']}>
                    <SelectFilter
                        initialFilterKey={sortFilterKey.current}
                        onChange={(e) => (sortFilterKey.current = e.target.value)}
                        headers={headers.filter(
                            (header) => ![...exclusions, 'Edit'].includes(header)
                        )}
                        filterValue={filterValues?.[sortFilterKey.current]?.query}
                        handleMenuFilterAction={handleMenuFilterAction}
                        resetFilter={() => setFilter(null)}
                    />
                    <IconButton aria-label='close' onClick={() => setPopoverOpen(false)}>
                        <CloseIcon fontSize='inherit' />
                    </IconButton>
                </div>
            </Popper>
        </div>
    );
};

const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: 'var(--color-gray-100)',
        color: 'var(--colour-porterbrook-blue)',
        boxShadow: theme.shadows[1],
        fontSize: '0.8rem',
    },
}));

export default InterventionsTable;
