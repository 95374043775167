import { createSlice } from '@reduxjs/toolkit';

const railwayPeriodsSlice = createSlice({
    name: 'railwayPeriods',
    initialState: {
        railwayPeriods: null,
    },
    reducers: {
        populateRailwayPeriods(state, action) {
            state.railwayPeriods = action.payload;
        },
    },
});

export const railwayPeriodsActions = railwayPeriodsSlice.actions;

export default railwayPeriodsSlice;
