import { createSlice } from '@reduxjs/toolkit';

const jobSlice = createSlice({
    name: 'jobs',
    initialState: {
        jobs: null,
    },
    reducers: {
        populateJobs(state, action) {
            state.jobs = action.payload;
        },
    },
});

export const jobActions = jobSlice.actions;

export default jobSlice;
